import React, { useState, useEffect } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { globalStyles, spacingStyles } from "../../assets/styles/global";
import { useTheme } from "@mui/material/styles"; // Import useTheme hook
import { useDispatch, useSelector } from "react-redux";
import {
  setDedicatedSpace,
  setChargeRate,
  setChargeRateGroup,
  setMonthlyRate,
  setMonthlyRateGroup,
  selectOnboardingState
} from "../../Services/Redux/Slices/Onboarding.Slice";
import OnboardingButton from "../Common/Buttons/OnboardingButton";
import RateInput from "../Common/RateInput";
import { convertCentsToDollars, convertDollarToCents } from "../../Services/Helpers/FinanceHelpers";

import Divider, { dividerClasses } from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

export default function Space() {
  const dispatch = useDispatch();
  const theme = useTheme(); // Access the current theme

  const space = useSelector((state) => state.onboarding.space);
  const [chargeRate, setChargeRatePrivate] = useState(space.chargeRate);
  const [chargeRateGroup, setChargeRateGroupPrivate] = useState(space.chargeRateGroup);
  const [monthlyRate, setMonthlyRatePrivate] = useState(space.monthlyRate); // Monthly rate for 1-on-1 training
  const [monthlyRateGroup, setMonthlyRateGroupPrivate] = useState(space.monthlyRateGroup); // Monthly rate for group classes


  useEffect(() => {
    //console.log("spaceUpdate", space);
    if (space) {
      setChargeRatePrivate(space.chargeRate);
      setChargeRateGroupPrivate(space.chargeRateGroup);
      setMonthlyRatePrivate(space.monthlyRate);
      setMonthlyRateGroupPrivate(space.monthlyRateGroup);
    }
  }, [space]);

  const handleChargeRateChange = (e) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : 0;
    const convertedValue = convertDollarToCents(value);
  
    dispatch(setChargeRate(convertedValue)); // Redux updates first
    setChargeRatePrivate(convertedValue); // Then local state updates
  };
  
  const handleMonthlyRateChange = (e) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : 0;
    const convertedValue = convertDollarToCents(value);
  
    dispatch(setMonthlyRate(convertedValue)); // Redux updates first
    setMonthlyRatePrivate(convertedValue); // Then local state updates
  };
  
  const handleChargeRateGroupChange = (e) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : 0;
    const convertedValue = convertDollarToCents(value);
  
    dispatch(setChargeRateGroup(convertedValue)); // Redux updates first
    setChargeRateGroupPrivate(convertedValue); // Then local state updates
  };
  
  const handleMonthlyRateGroupChange = (e) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : 0;
    const convertedValue = convertDollarToCents(value);
  
    dispatch(setMonthlyRateGroup(convertedValue)); // Redux updates first
    setMonthlyRateGroupPrivate(convertedValue); // Then local state updates
  };

  const incrementChargeRate = () => setChargeRate((prev) => prev + 1);
  const decrementChargeRate = () => setChargeRate((prev) => Math.max(prev - 1, 0));

  const incrementMonthlyRate = () => setMonthlyRate((prev) => prev + 10);
  const decrementMonthlyRate = () => setMonthlyRate((prev) => Math.max(prev - 10, 0));

  const incrementChargeRateGroup = () => setChargeRateGroup((prev) => prev + 1);
  const decrementChargeRateGroup = () => setChargeRateGroup((prev) => Math.max(prev - 1, 0));

  const incrementMonthlyRateGroup = () => setMonthlyRateGroup((prev) => prev + 10);
  const decrementMonthlyRateGroup = () => setMonthlyRateGroup((prev) => Math.max(prev - 10, 0));


  const dedicatedSpace = useSelector(
    (state) => state.onboarding.space.dedicatedSpace
  );

  return (
    <Stack
      spacing={4}
      sx={{ textAlign: "left", width: "100%", pb: "30px" }}
    >

      <Typography
        variant="h5"
        sx={{ color: theme.palette.text.primary }} // Dynamic color based on theme
      >
        Specify your rental rates
      </Typography>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={6}
        sx={{ textAlign: "left", width: "100%", pb: "30px", pt: "30px" }}
      >
        <Grid>
          <Divider sx={{ color: theme.palette.text.primary, marginBottom: '20px' }} textAlign="left">1-on-1 Training Sessions</Divider>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              {/* <Typography variant="body1" color={theme.palette.text.secondary}>
            Hourly rate for 1-on-1 training sessions
          </Typography> */}
              <RateInput
                chargeRate={chargeRate}
                handleChargeRateChange={handleChargeRateChange}
                incrementChargeRate={incrementChargeRate}
                decrementChargeRate={decrementChargeRate}
                timeUnit="hour"
                caption="$15 per hour is the average."
              />
            </Grid>
          </Grid>

          {/* Monthly Rate for 1-on-1 Training */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              {/* <Typography variant="body1" style={{ ...spacingStyles.mb20 }} color={theme.palette.text.secondary}>
          Monthly rate for 1-on-1 training sessions
        </Typography> */}
              <RateInput
                chargeRate={monthlyRate}
                handleChargeRateChange={handleMonthlyRateChange}
                incrementChargeRate={incrementMonthlyRate}
                decrementChargeRate={decrementMonthlyRate}
                timeUnit="month"
                caption="$500 per month is the average."
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Divider sx={{ color: theme.palette.text.primary, marginBottom: '20px' }} textAlign="left">Group Sessions/Classes</Divider>

          {/* Monthly Rate for 1-on-1 Training */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              {/* <Typography variant="body1" style={{ ...spacingStyles.mb20 }} color={theme.palette.text.secondary}>
          Hourly rate for group/class sessions
        </Typography> */}
              <RateInput
                chargeRate={chargeRateGroup}
                handleChargeRateChange={handleChargeRateGroupChange}
                incrementChargeRate={incrementChargeRateGroup}
                decrementChargeRate={decrementChargeRateGroup}
                timeUnit="hour"
                caption="$25 per hour is the average."
              />
            </Grid>
          </Grid>


          {/* Monthly Rate for Group Classes */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              {/* <Typography variant="body1" style={{ ...spacingStyles.mb20 }} color={theme.palette.text.secondary}>
          Monthly rate for group/class sessions
        </Typography> */}
              <RateInput
                chargeRate={monthlyRateGroup}
                handleChargeRateChange={handleMonthlyRateGroupChange}
                incrementChargeRate={incrementMonthlyRateGroup}
                decrementChargeRate={decrementMonthlyRateGroup}
                timeUnit="month"
                caption="$750 per month is the average."
              />
            </Grid>

          </Grid>
        </Grid>
      </Stack>
      {/* <Box>
        <Typography
          variant="h5"
          sx={{ color: theme.palette.text.primary }} // Dynamic color based on theme
        >
          What is the best way to describe your space?
        </Typography>
        <Typography variant="body1" style={{ ...spacingStyles.mb20 }} color={theme.palette.text.secondary}>
          Select the option that applies
        </Typography>
        <Box
          sx={{
            pt: 2,
            pb: 2,
            ...globalStyles.w100,
            textAlign: "left",
          }}
        >
          <OnboardingButton
            showCusIcon={false}
            onClick={() => dispatch(setDedicatedSpace(true))}
            text={"Open Gym Floor"}
            selected={dedicatedSpace}
            pinName=""
          />

          <OnboardingButton
            showCusIcon={false}
            onClick={() => dispatch(setDedicatedSpace(false))}
            text={"Group Fitness Space"}
            selected={!dedicatedSpace}
            pinName=""
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary, // Dynamic color based on theme for lighter text
            // ...spacingStyles.ml10,
            marginTop: "10px",
          }}
        >
          {dedicatedSpace
            ? "Ex. Area such as a gym floor with equipment or free weights."
            : "Ex. Area where Hoppers will have control over who enters the room. Example: spinning room with sound system."}
        </Typography>
      </Box> */}
    </Stack>
  );
}
