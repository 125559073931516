import React, { useMemo } from 'react';
import { Box, Typography, FormControl, Select, MenuItem, List, ListItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';

const LeaseAgreement = ({
  gymName,
  gymAddress,
  trainerName,
  trainerEmail,
  trainerContact,
  amount,
  state,
  onAgreementChange,
  mode,
  paymentFrequency
}) => {

  //console.log(mode);
  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode: mode === "light" ? "light" : "dark", // Ensure only light/dark is passed
        background: {
          default: "#ffffff", // White background for light mode
          paper: "#f7f7f7", // Slightly gray background for better readability
        },
        text: {
          primary: "#000000", // Ensure black text for light mode
          secondary: "#333333", // Dark gray for secondary text
        },
      },
    }),
    [mode]);


  const formattedDueDate = (() => {
    const now = moment(); // Convert dueDate to a Moment object

    if (paymentFrequency === "monthly") {
      return `the ${now.format("Do")} of each month`; // Example: "5th of each month"
    } else if (paymentFrequency === "bi-weekly") {
      return `every other ${now.format("dddd")}`; // Example: "every other Monday"
    } else if (paymentFrequency === "weekly") {
      return `every ${now.format("dddd")}`; // Example: "every Monday"
    }
  })();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        maxHeight: 250,
        borderRadius: 1,
        overflowY: 'scroll',
        border: '1px solid #ccc',
        p: 2,
        mb: 2,
        textAlign: "left",
        bgcolor: theme.palette.background.paper,
        color: theme.palette.text.primary
      }}>

        <Typography variant="h5" fontWeight={600} textTransform={'capitalize'} textAlign={'center'} paragraph sx={{ color: theme.palette.text.primary }}>
          {paymentFrequency} Lease Agreement
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          This <span style={{ textTransform: "capitalize" }}>{paymentFrequency}</span> Lease Agreement ("Agreement") is made and entered into as of the Effective Date by and between:
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>Gym Name:</strong> {gymName} ("Gym")
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>Address:</strong> {gymAddress}
        </Typography>
        {/* <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>Contact Person:</strong> {gymRepresentative}
        </Typography> */}

        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>AND</strong>
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>Trainer Name:</strong> {trainerName} ("Trainer")
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>Trainer Contact:</strong> {trainerEmail}
        </Typography>

        <Typography variant="h6" paragraph sx={{ color: theme.palette.text.primary }}>
          1. RENTAL TERMS
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>1.1 Rental Space:</strong> The Gym agrees to lease space within its facility to the Trainer for the purpose of conducting personal training sessions.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>1.2 Term:</strong> This Agreement shall be on a <span>{paymentFrequency}</span>  basis, commencing on the Effective Date.
        </Typography>


        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>1.3 Rent Payment:</strong> Trainer agrees to pay the Gym a
          <span style={{ textTransform: "capitalize", marginRight: "4px" }}>
            {" "}{paymentFrequency.replace("-", " ")}
          </span>
            rent of ${amount}, billed on
          <span style={{  }}> {formattedDueDate}</span>.
        </Typography>


        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          <strong>1.4 Payment Method:</strong> Payments shall be made via the HopperFit App.
        </Typography>

        <Typography variant="h6" paragraph sx={{ color: theme.palette.text.primary }}>
          2. TERMINATION
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          Either party may terminate this Agreement at any time by unsubscribing in the HopperFit app. Termination shall be effective immediately or on a mutually agreed-upon date.
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
          No penalties or additional fees shall be imposed for terminating this Agreement.
        </Typography>

        <Typography variant="h6" paragraph sx={{ color: theme.palette.text.primary }}>
          3. TRAINER’S RESPONSIBILITIES
        </Typography>
        <List >
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Trainer shall comply with all Gym rules and regulations.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Trainer is responsible for ensuring that their clients follow Gym policies.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Trainer shall leave the rented space in clean condition after each session.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h6" paragraph sx={{ color: theme.palette.text.primary }}>
          4. GYM’S RESPONSIBILITIES
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Gym shall provide the Trainer access to the designated space during agreed operating hours.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Gym shall maintain a safe and clean training environment.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Gym shall not interfere with Trainer’s business operations as long as they comply with Gym policies.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h6" paragraph sx={{ color: theme.palette.text.primary }}>
          5. LIABILITY & INDEMNIFICATION
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Trainer acknowledges that they are an independent contractor and not an employee of the Gym.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              Trainer agrees to indemnify and hold harmless the Gym from any claims, damages, or liabilities arising from their training services.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              The Gym shall not be responsible for any loss or damage to Trainer’s equipment or belongings.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h6" paragraph sx={{ color: theme.palette.text.primary }}>
          6. GENERAL PROVISIONS
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              <strong>Entire Agreement:</strong> This Agreement constitutes the entire agreement between the parties and supersedes any prior understandings.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              <strong>Modifications:</strong> Any modifications must be in writing and signed by both parties.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              <strong>Governing Law:</strong> This Agreement shall be governed by the laws of the state of {state}.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
              <strong>Severability:</strong> If any provision of this Agreement is found invalid, the remainder shall remain in full force and effect.
            </Typography>
          </ListItem>
        </List>
      </Box>
      <Box sx={{ mb: 6 }}>
        <FormControl fullWidth>
          <Typography variant="body1" sx={{ textAlign: "left", color: theme.palette.text.primary }} gutterBottom >
            By selecting "I Agree" below, you acknowledge that you have read, understood, and accepted the terms of this agreement.
          </Typography>
          <Select onChange={onAgreementChange} defaultValue="">
            <MenuItem value="">Select an option</MenuItem>
            <MenuItem value="agree">I Agree</MenuItem>
            <MenuItem value="disagree">I Do Not Agree</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>

  );
};

export default LeaseAgreement;
