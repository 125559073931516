import axios from "axios";
import config from '../../config';

export const getPublishKey = async () => {
  const response = await axios.get(config.apiUrl + "Stripe/getPublishKey/");
  return response;
};



export const getClientSecret = async (bookingID, token) => {
  console.log(bookingID);
  const response = await axios.get(config.apiUrl + "Stripe/checkOut/"+bookingID+"/"+token);
  return response;
};


export const getClientSubscriptionSecret = async ({ plan, price, slug }, token) => {
  //console.log(slug, plan);
   const response = await axios.get(config.apiUrl + "Stripe/checkOutSub/"+slug+"/"+plan+"/"+price+"/"+token);
   return response;
};

export const getStartTrial = async (token) => {
  const response = await axios.get(config.apiUrl + "Stripe/startTrial/"+token);
  return response;
};


export const getTechFee = async (cost) => {
  const response = await axios.get(config.apiUrl + "Stripe/getTechFee/"+cost);
  return response;
};
