import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../Common/Buttons/Button"; // Custom Button
import { Box, TextField, Typography, InputAdornment, IconButton, Popover } from "@mui/material";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import { addEmail, addPhone } from "../../Services/Redux/Slices/User.Slice";
import { setPending, addAuthRoleID } from "../../Services/Redux/Slices/Auth.Slice";
import { LoginHandler, verifyLogin } from "../../Services/APIHandlers/AuthApi";
import jwtDecode from "jwt-decode";
import { formStyles, colors, baseTheme } from "../../assets/styles/global"; // Import the formStyles
import AuthContext from "../../Navigation/AuthWrapper";

import { useTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useDynamicTheme } from "../../Services/Helpers/DynamicTheme";

const LoginForm = ({ onSuccess, mode = "dark" }) => {
  const { darkMode } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const email = useSelector((state) => state.user.email);

  const theme = useTheme();
  const dynamicTheme = useDynamicTheme(theme, mode);

  // Define all the styles in one object, including formStyles
  const styles = {
    container: {
      maxWidth: "sm",
      margin: "0 auto",
      textAlign: "left",
    },
    title: {
      color: theme.palette.text.primary
    },
    description: {
      color: theme.palette.text.secondary,
      marginBottom: "30px",
    },
    errorText: {
      ...formStyles.errorText, // Error text from formStyles
    },
    input: {
      ...formStyles[mode === "light" ? "input_light" : "input"], // Input styles from formStyles based on mode
    },
    textField: {
      ...formStyles.textField, // MUI-specific styles for TextField
    },
    popover: {
      backgroundColor: "yellow",
      color: mode === "light" ? "black" : "black",
    },
    buttonContainer: {
      marginTop: "20px",
    },
  };

  const submitEmail = async () => {
    if (!validateEmail(email)) {
      setError("Invalid Email Address");
      return;
    }
    try {
      const response = await LoginHandler(email);
      const data = response.data;
      if (data.error) {
        const loginResponse = await verifyLogin(email);
        const pendingToken = loginResponse?.data?.verify_token;
        const userInfo = jwtDecode(pendingToken);
        //console.log(userInfo);

        dispatch(setPending(pendingToken));
        dispatch(addAuthRoleID(userInfo.role_id));
        dispatch(addPhone(userInfo.phone));
        dispatch(addEmail(userInfo.email));
        onSuccess();
        return;
      } else {
        setError(data.error);
        return;
      }
    } catch (err) {
      setError("Login Error. Please Contact Admin.");
      console.error(err);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <ThemeProvider theme={dynamicTheme} >
      <Box sx={styles.container}>
        <img
          className="welcomeLogo"
          src={darkMode ? require("../../assets/logo_light.png") : require("../../assets/logo_dark.png")}
          alt="Logo"
        />
        <Typography variant="h5" sx={styles.title}>
          Login
        </Typography>
        {!error && (
          <Typography sx={styles.description}>
            Login with just your email address.
          </Typography>
        )}
        {error && <Typography sx={styles.errorText}>{error}</Typography>}

        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Email"
            error={!!error}
            onChange={(e) => dispatch(addEmail(e.target.value))}
            autoFocus
            InputProps={{
              style: styles.input, // Apply input style from styles (which includes formStyles)
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-describedby={id} onClick={handleInfoClick} >
                    <PrivacyTipIcon color={colors.gray.color} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={styles.textField} // Apply MUI-specific formStyles
          />
        </Box>

        {/* Popover with OTP information */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          elevation={1}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: styles.popover.backgroundColor,
            },
          }}
        >
          <Typography sx={{ p: 2, color: styles.popover.color }}>
            We use One-Time Password (OTP) via text message to verify your email during login.
          </Typography>
        </Popover>

        <Box sx={styles.buttonContainer}>
          <Button fill="solid" text="Login" onClick={submitEmail} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LoginForm;
