import React, { useState, useEffect } from 'react';
import { Rating, Typography, Box, Tooltip, IconButton, Avatar, TextField } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from "@mui/material/styles";

const TrainerRating = ({ bookingUser }) => {

  const theme = useTheme();
  const [comment, setComment] = useState('');

  const categories = [
    {
      label: 'Punctuality',
      description: 'Were they on time and reliable for sessions?',
    },
    {
      label: 'Cleanliness',
      description: 'Did they maintain a tidy workspace and follow hygiene protocols?',
    },
    {
      label: 'Adherence to Gym Rules',
      description: 'Did they follow all facility policies and guidelines?',
    },
    {
      label: 'Respect for Equipment',
      description: 'Did they use gym equipment properly and ensure it was maintained?',
    },
    {
      label: 'Appearance & Attire',
      description: 'Did the trainer present themselves in a professional and appropriate manner that aligns with the gym\'s dress code and expectations?',
    }
  ];

  const [ratings, setRatings] = useState(
    categories.reduce((acc, category) => {
      acc[category.label] = 3; // Default rating of 3
      return acc;
    }, {})
  );

  const handleRatingChange = (category, newValue) => {
    setRatings({ ...ratings, [category]: newValue });
  };
  const handleCommentChange = (category, newComment) => {
    setRatings({
      ...ratings,
      [category]: { ...ratings[category], comment: newComment }
    });
  };

  // Calculate average rating
  const averageRating = Object.values(ratings).reduce((sum, rating) => sum + rating, 0) / categories.length;

  useEffect(() => {
    console.log(bookingUser);
  }, []);

  return (
    <>
      <Box sx={{ maxWidth: 440, mx: 'auto', p: 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 1, }}>
        <Typography variant="h5" color={theme.palette.text.primary} display="flex" justifyContent= "center" alignItems={'center'} gutterBottom>
          Hopper Review -  {averageRating} <StarIcon sx={{ verticalAlign: 'middle', fontSize: '1em', color: 'gold' }} />'s
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="center" gap={2} sx={{ pb: 3 }}>
          {/* Trainer Image */}
          <Avatar
            src={bookingUser.image}
            sx={{ width: 45, height: 45, borderRadius: "50%" }}
            alt="Trainer"
          />
          {/* Trainer Info */}
          <Box sx={{ textAlign: "left" }}>
            <Typography
              variant="h6"
              // fontWeight={600}
              color={theme.palette.text.primary}
            >
              {bookingUser.name}{" "}
              {bookingUser.last_name}{" "}
            </Typography>

          </Box>
        </Box>


        {categories.map((category) => (
          <Box key={category.label} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start', mb: 3 }}>
            <Box component="legend" sx={{ display: 'flex', alignItems: 'center', gap: .5, flexShrink: 0 }}>
              <Typography variant="pp" fontWeight="600"
                color={theme.palette.text.primary} >{category.label}</Typography>
              <Tooltip
                title={
                  <Typography sx={{ fontSize: '1rem' }}>
                    {category.description}
                  </Typography>
                }
                arrow>
                <IconButton
                  size="small"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Rating
              name={category.label}
              value={ratings[category.label]}
              onChange={(event, newValue) => handleRatingChange(category.label, newValue)}
              precision={1}
              size="large"
            />
          </Box>
        ))}

        {/* Single Comment Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" color={theme.palette.text.primary} gutterBottom>
            Additional Comments
          </Typography>
          <TextField
            fullWidth
            label="Enter your comments here"
            variant="outlined"
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Box>
      </Box>
      <Box sx={{ p: 5 }}></Box>
    </>
  );
};

export default TrainerRating;