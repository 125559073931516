import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  verify_SMSPin,
  textOTP,
  emailOTP,
  verify_EmailPin,
} from "../../Services/APIHandlers/AuthApi";
import { addBusiness } from "../../Services/Redux/Slices/Business.Slice";
import {
  createAuth,
  addAccountData,
} from "../../Services/Redux/Slices/Auth.Slice";
import { addUser } from "../../Services/Redux/Slices/User.Slice";
import { addBookingList } from "../../Services/Redux/Slices/Booking.Slice";
import { Box, TextField, Typography, CircularProgress } from "@mui/material"; // MUI components
import { Button } from "../Common/Buttons/Button"; // Custom Button component
import { spacingStyles } from "../../assets/styles/global";
import { useTheme } from '@mui/material/styles';


const OTPForm = ({ onSuccess, mode = "dark" }) => {
  const dispatch = useDispatch();
  const pendingToken = useSelector((state) => state.auth.pendingToken);
  const state = useSelector((state) => state.user);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const phone = useSelector((state) => state.user.phone);
  const email = useSelector((state) => state.user.email);

    // Countdown timers
    const [smsCountdown, setSmsCountdown] = useState(0);
    const [emailCountdown, setEmailCountdown] = useState(0);
    const [loading, setLoading] = useState(false);

  const [sendCodeTo, setSend] = useState("text");

  const theme = useTheme();

  const getEmailOtp = async () => {
    if (emailCountdown > 0) return;
    setSend("email");
    setValue("");

    try {
      await emailOTP(pendingToken);
      setEmailCountdown(30);
    } catch (err) {
      console.error(err);
    }
  };

  const getTextOtp = async () => {
    if (smsCountdown > 0) return;
    setSend("text");
    setValue("");

    try {
      await textOTP(pendingToken);
      setSmsCountdown(30);
    } catch (err) {
      console.error(err);
    }
  };

  // Countdown effect for SMS
  useEffect(() => {
    let timer;
    if (smsCountdown > 0) {
      timer = setTimeout(() => setSmsCountdown(smsCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [smsCountdown]);

  // Countdown effect for Email
  useEffect(() => {
    let timer;
    if (emailCountdown > 0) {
      timer = setTimeout(() => setEmailCountdown(emailCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [emailCountdown]);

  const submitPin = async () => {
    //console.log(value); 
    if (value.length !== 4) {
      setError("Invalid Pin Code");
      return;
    }
    setError("");
    setLoading(true);

    try {
      let response;

      if (sendCodeTo === "email") {
        response = await verify_EmailPin(value, pendingToken);
      } else {
        response = await verify_SMSPin(value, pendingToken);
      }

      //console.log(response);
      if (response.data?.status === "invalid") {
        setError("Invalid Pin Code");
        return;
      }

      await dispatch(addUser({ ...response.data.userData }));

      if (
        response.data.userData.role_id === "2" ||
        response.data.userData.role_id === "3"
      ) {
        await dispatch(addBusiness({ ...response.data.businessData }));
        await dispatch(addBookingList({ ...response.data.businessData }));
        await dispatch(addAccountData({ ...response.data.accountData }));
      }

      await dispatch(
        createAuth({ token: response.data.token, role_id: response.data.userData.role_id })
      );
      onSuccess(response.data.userData.role_id);
    } catch (err) {
      console.error(err);
    }finally {
      setLoading(false); // Re-enable button after API call
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Restrict to numbers and max length of 4
    if (/^\d*$/.test(inputValue) && inputValue.length <= 4) {
      setValue(inputValue);
    }
  };


  return (
    <Box sx={{ textAlign: "center", ...spacingStyles.formSpace }}>
      <Typography variant="h6" color={theme.palette.text.secondary} gutterBottom>
        Enter the verification code we {sendCodeTo}ed to you.
      </Typography>
      {error && (
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      )}
      {email && sendCodeTo === "email" && (
        <>
          <Typography sx={{ pt: "40px" }} variant="body1" color="textSecondary">
            Pin was sent to {email}
          </Typography>
          <Typography sx={{ pt: "10px" }} variant="body1" color="textSecondary">
            {emailCountdown > 0 ? (
              <span className="textButton" style={{ color: "gray", cursor: "default" }}>
                Email Re-Sent - Try Again in {emailCountdown}s
              </span>
            ) : (
              <span className="textButton" style={{ color: theme.palette.primary.main, cursor: "pointer" }} onClick={getEmailOtp}>
                Resend Email
              </span>
            )}
          </Typography>
        </>
      )}

      {phone && sendCodeTo === "text" && (
        <>
          <Typography sx={{ pt: "40px" }} variant="body1" color="textSecondary">
            Pin was sent to {phone}
          </Typography>
          <Typography sx={{ pt: "10px" }} variant="body1" color="textSecondary">
            {smsCountdown > 0 ? (
              <span className="textButton" style={{ color: "gray", cursor: "default" }}>
                Text Re-Sent - Try Again in {smsCountdown}s
              </span>
            ) : (
              <span className="textButton" style={{ color: theme.palette.primary.main, cursor: "pointer" }} onClick={getTextOtp}>
                Resend Text Message
              </span>
            )}
          </Typography>
        </>
      )}


      <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 3 }}>
        <TextField
          variant="outlined"
          label="One Time Pin"
          error={!!error}
          value={value} // Bind the input to the value state
          onChange={handleChange} // Update the value on change
          inputProps={{
            maxLength: 4,
            style: { textAlign: "center", fontSize: "24px", letterSpacing: "10px" }, // Center text and adjust spacing for clarity
          }}
          sx={{
            width: "220px", // Enough width for 4 digits with some spacing
            "& input": {
              padding: "10px",
            },
          }}
          autoFocus
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button fill="solid" text={loading ? <CircularProgress size={20} color="inherit" /> : "Verify ID"} onClick={submitPin} disabled={loading} />
      </Box>

      <Typography variant="h6" sx={{ mt: 4 }}>
        Didn't receive our text?
      </Typography>
      {phone && sendCodeTo === "text" && (
        <Typography variant="body2" color="textSecondary" sx={{ pt: 1 }}>
          <span className="textButton" style={{ color: theme.palette.primary.main, cursor: "pointer" }} onClick={getEmailOtp}>
            Email me
          </span>{" - "}{email}
        </Typography>
      )}
      {email && sendCodeTo === "email" && (
        <Typography variant="body2" color="textSecondary" sx={{ pt: 1 }}>
          <span className="textButton" style={{ color: theme.palette.primary.main, cursor: "pointer" }} onClick={getTextOtp}>
            Text me
          </span>{" - "}{phone}
        </Typography>
      )}

    </Box>
  );
};

export default OTPForm;
