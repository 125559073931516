import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Tab, Tabs as MuiTabs } from '@mui/material';
import { Button } from '../Common/Buttons/Button';
import StarIcon from '@mui/icons-material/Star';
import DetailsSchedule from '../Calendar/DetailsSchedule';
import { convertCentsToDollars } from '../../Services/Helpers/FinanceHelpers';
import TermAgreementForm from './TermAgreementForm';
import SubscriptionPlanForm from './SubscriptionPlanForm';

const OptionsForm = ({
  scheduleData,
  Data,
  daySelect,
  timeSelect,
  openPanel,
  spacingStyles,
  setOptionSelect,
  setPaymentFrequency,
}) => {

  const theme = useTheme();
  const determineAvailableTabs = (space) => {

    const { chargeRate, chargeRateGroup, monthlyRate, monthlyRateGroup } = space;

    // If all values are 0 or null, only show "Term Agreement"
    if (
      (!chargeRate || chargeRate === "0") &&
      (!chargeRateGroup || chargeRateGroup === "0") &&
      (!monthlyRate || monthlyRate === "0") &&
      (!monthlyRateGroup || monthlyRateGroup === "0")
    ) {
      return ["Term Agreement"];
    }

    // Otherwise, include relevant booking options
    const tabs = [];

    if (chargeRate && chargeRate !== "0") {
      tabs.push("Instant 1-on-1");
    }

    if (chargeRateGroup && chargeRateGroup !== "0") {
      tabs.push("Instant Group");
    }

    if (monthlyRate && monthlyRate !== "0") {
      tabs.push("Monthly 1-on-1");
    }

    if (monthlyRateGroup && monthlyRateGroup !== "0") {
      tabs.push("Monthly Group");
    }

    //console.log(tabs);
    return tabs;
  };
  // Use this function to determine available tabs dynamically
  const availableTabs = determineAvailableTabs(Data.details.space);

  const [activeTab, setActiveTab] = useState(availableTabs[0]);
  const handleTabChange = (event, newValue) => {
    setOptionSelect(newValue);
    setActiveTab(newValue)
  };

  // useEffect(() => {
  //   console.log(Data);
  // }, []);


  return (
    <div className="options-form-container">
      <div className="options-form">
        <h2 style={{ marginTop: 'unset' }}>Options to Hop Here</h2>
        <p>Select the Booking option that best fits your business.</p>
        <div>
          <MuiTabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            {availableTabs.map((tabName) => (
              <Tab
                key={tabName}
                label={tabName}
                value={tabName}
                sx={{ fontSize: "16px", fontWeight: "bold", textTransform: "capitalize" }} />
            ))}
          </MuiTabs>
        </div>
        {(activeTab === 'Instant 1-on-1' || activeTab === 'Instant Group') && (
          <div style={{ paddingTop: '20px' }}>
            <DetailsSchedule schedule={scheduleData} />
            <div className="footer-profile">
              <div
                className="flexRow flexAround footer-inner"
                style={{ ...spacingStyles.w100 }}
              >
                <div className="flexCol centerDiv">
                  <div className="flexRow price">
                    <span className="h1 white" style={{ fontSize: 45 }}>
                      ${convertCentsToDollars(
                        activeTab === 'Instant Group'
                          ? Data.details.space.chargeRateGroup
                          : Data.details.space.chargeRate
                      )}
                    </span>
                    <span className="white" style={{ ...spacingStyles.pl5 }}>
                      /booking
                    </span>
                  </div>
                  <h5 className="" style={{ ...spacingStyles.mt0, color: theme.palette.text.primary }}>
                    {" "}
                    <StarIcon /> with membership
                  </h5>
                </div>
                <div
                  style={{ ...spacingStyles.w50 }}
                  onClick={() => {
                    if (daySelect !== null && timeSelect !== null) {
                      openPanel("one-time", {
                        date: daySelect,
                        time: timeSelect,
                        chargeRate: activeTab === 'Instant Group'
                          ? Data.details.space.chargeRateGroup
                          : Data.details.space.chargeRate
                      });
                    }
                  }}
                >
                  <Button
                    fill={(daySelect === null || timeSelect === null) ? 'outline' : 'solid'}
                    text="Book Now"
                    disabled={daySelect === null || timeSelect === null}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {(activeTab === 'Monthly 1-on-1' || activeTab === 'Monthly Group') && (
          <div style={{ paddingTop: '20px' }}>
            <div style={{ minHeight: '300px' }}>
              <SubscriptionPlanForm
                slug={Data.profile.slug}
                monthlyRate={activeTab === 'Monthly Group' ? Data.details.space.monthlyRateGroup : Data.details.space.monthlyRate}
                setPaymentFrequency={setPaymentFrequency}
                openPanel={openPanel}
              ></SubscriptionPlanForm>
            </div>
          </div>
        )}
        {activeTab === 'Term Agreement' && (
          <div style={{ minHeight: '300px' }}>
            <TermAgreementForm slug={Data.profile.slug}></TermAgreementForm>
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionsForm;