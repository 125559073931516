import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// API
import { getSiteData } from "../../Services/APIHandlers/BookingApi";
import GymDetails from "./Gym/GymDetails";
import TrainerDetails from "./Trainer/TrainerDetails";
import randomNumber from "../../Services/Helpers/RandomNumber";

import { useDispatch, useSelector } from "react-redux";
import {
  selectSite,
  updateNumRatings,
  updateRating,
} from "../../Services/Redux/Slices/Booking.Slice";

import CleanWrapper from "../../Components/Common/CleanWrapper";

const ProfileForward = () => {
  // Get the slug parameter from the URL
  const { slug } = useParams();

  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.booking.selectedSite);

  useEffect(() => {
    //console.log("Load Profile");
    const fetchData = async () => {
      try {
        const response = await getSiteData(slug); // Replace with your data-fetching logic
        //console.log(response.data);

        const publicData = response.data.publicData;
        dispatch(selectSite(publicData));

        //console.log(publicData);

        const numRatings = publicData?.ratings?.numRatings
        dispatch(updateNumRatings(numRatings));

        const rating = publicData?.ratings?.averageRating;
        dispatch(updateRating(rating));
        
        //console.log(selectedSite);
      } catch (error) {
        // Handle any errors here
      }
    };

    fetchData();
  }, [slug, dispatch]);

  if (selectedSite?.type === "studio") {
    return (
      // <CleanWrapper>
        <GymDetails Data={selectedSite} />
      // </CleanWrapper>
    );
  }

  if (selectedSite?.type === "trainer") {
    return (
      <CleanWrapper>
        <TrainerDetails Data={selectedSite} />
      </CleanWrapper>
    );
  }

  return null;
};

export default ProfileForward;
