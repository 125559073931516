import React, { useEffect, useContext, useCallback } from "react";
import AuthContext from "../../Navigation/AuthWrapper";
import { useDispatch, useSelector } from "react-redux";

import { spacingStyles } from "../../assets/styles/global";
import {
  addLocation,
  updateLocationAccess,
} from "../../Services/Redux/Slices/Location.Slice";
import "../../assets/styles/global.css";

import OneWeekDaySelect from "../Calendar/OneWeekDaySelect";
import DashBookingList from "./DashBookingList";

import {
  fetchSitesNearMe,
  getMySchedule,
} from "../../Services/APIHandlers/BookingApi";
import {
  addBookedMe,
  addMyBookings,
} from "../../Services/Redux/Slices/MyBookings.slice";
import { addSites } from "../../Services/Redux/Slices/SearchSites.Slice";
import { fetchSchedule } from "../../Services/APIHandlers/ScheduleApi";

const DashUpcoming = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);
  const schedule = useSelector((state) => state.schedule);
  const location = useSelector((state) => state.location);

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
      default:
        break;
    }
  }

  const getLocation = useCallback(async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          addLocation({
            accuracy: position.coords.accuracy,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            speed: position.coords.speed,
          })
        );
        dispatch(updateLocationAccess(true));
      }, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
      dispatch(updateLocationAccess(false));
      // dispatch(updateLocationAccess(true));
      return;
    }
  }, [dispatch]);

  const getScehdule = useCallback(async () => {
    try {
      const response = await fetchSchedule(token);
      //console.log(response.data);
      dispatch(addBookedMe(response.data.bookedMe));
      dispatch(addMyBookings(response.data.myBookings));
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, token]);

  const getSites = useCallback(async () => {
    try {
      if (location?.coords?.latitude && location?.coords?.longitude) {
        const response = await fetchSitesNearMe(
          location?.coords?.latitude,
          location.coords.longitude
        );
        if (response.status == 200) {
          dispatch(addSites(response.data));
          //console.log(response.data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, location.coords?.latitude, location.coords.longitude]);

  // useEffect(() => {
  //   //getScehdule();
  //   getLocation();
  //   //console.log("Dashbaord.Screen: is running");
  //   if (location) {
  //     getSites();
  //   }
  // }, [getLocation, getScehdule, getSites, location]);

  return (
    <>
      <div className="dash-box" style={{ ...spacingStyles.p10 }}>
        <OneWeekDaySelect
          schedule=""
          daySelect=""
          blackOuts=""
        ></OneWeekDaySelect>
        <div style={{ ...spacingStyles.p10, ...spacingStyles.pt20 }}>
          <DashBookingList></DashBookingList>
        </div>
      </div>

    </>
  );
};

export default DashUpcoming;
