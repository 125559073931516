import React, { useEffect, useState, useContext } from 'react';
import { BottomNavigation, BottomNavigationAction, Typography } from '@mui/material';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PaidIcon from '@mui/icons-material/Paid';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';

import { nav } from "../Services/Constants/navigation";
import { useTheme } from '@mui/material/styles';
import { Box, MenuList, MenuItem, ListItemIcon, ListItemText, Collapse, Stack } from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import MapIcon from '@mui/icons-material/Map';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { FaTimes } from "react-icons/fa";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import AuthContext from "./AuthWrapper";
import OnboardingSideMenu from './OnboardingSideMenu';
import DarkModeSwitch from '../Components/Common/DarkModeSwitch';
import { spacingStyles } from '../assets/styles/global';

import useOnboardingStatus from '../Services/Hooks/useOnboardingStatus';
import { text } from '@fortawesome/fontawesome-svg-core';


export const DeskSideMenu = ({ activeScreen, onMenuClick }) => {
    const [isMyListingOpen, setMyListingOpen] = useState(true);
    const completionStatus = useOnboardingStatus();
    const auth = useContext(AuthContext);
    const isAuthenticated = auth.token !== "";
    const { darkMode, toggleDarkMode, role_id } = useContext(AuthContext);

    //const userData = JSON.parse(localStorage.getItem("user"));
    const businessData = JSON.parse(localStorage.getItem("business"));
    //console.log(userData, businessData);

    // if role_id = 2  && slug does not == "" or null

    const theme = useTheme();
    const navigation = useNavigate();
    const toggleMyListingMenu = () => setMyListingOpen(!isMyListingOpen);

    const menuTextStyles = {
        primaryTypographyProps: {
            style: { color: theme.palette.text.primary },
        },
    };

    // Shared navigation filtering logic
    const filteredNav = nav.filter((route) => {
        ;
        if ((route.path === "/SiteOnboarding" && role_id === 3) ||
            (route.path === "/TrainerOnboarding" && role_id === 2)) {
            return false;
        }
        return route.isMenu && (!isAuthenticated || route.isPrivate);
    });

    return (
        <div className="desk-side-menu">
            <div className="menu-header">
                <div className="logo" style={{ ...spacingStyles.mr20, ...spacingStyles.ml10, ...spacingStyles.mt10 }}
                    onClick={() => {
                        navigation(isAuthenticated ? (role_id === 2 ? "/GymDash" : "/TrainerDash") : "/Login");
                    }}>
                    <img
                        className="noLogLogo"
                        src={darkMode ? require("./../assets/logo_light.png") : require("./../assets/logo_dark.png")}
                        alt="Logo"
                    />
                </div>
                <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            </div>

            <MenuList className="side-menu-list">
                <Stack>
                    {isAuthenticated && (
                        <MenuItemComponent route={{ name: "Home", path: "/Home" }} />

                    )}
                    {isAuthenticated && businessData?.slug && (
                        <MenuItem
                            className="mui-menu-item"
                            sx={{
                                borderRadius: '5em',
                                width: '95%',
                                margin: 'auto',
                                backgroundColor: "transparent",
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                }
                            }}
                            onClick={() =>
                                navigation(`/${businessData?.slug}`)
                            }
                        >
                            <ListItemIcon style={{ minWidth: "40px", color: darkMode ? "#fff" : "#000" }}>
                                <RocketLaunchIcon fontSize="small" />

                            </ListItemIcon>
                            <ListItemText
                                primary="My Listing"
                                primaryTypographyProps={{
                                    style: {
                                        color: theme.palette.text.primary,
                                        fontWeight: "normal",
                                        textAlign: "left"
                                    }
                                }}
                            />
                        </MenuItem>
                    )}

                    {!isAuthenticated && (
                        <>
                            <MenuItemComponent route={{ name: "List your Gym", path: "/register", icon: <MapOutlinedIcon /> }} extraClass="reg-btn" />
                            <MenuItemComponent route={{ name: "Login", path: "/login", icon: <LoginIcon /> }} darkMode={darkMode} />
                            <MenuItemComponent route={{ name: "Search", path: "/Search", icon: <SearchIcon /> }}  darkMode={darkMode}/>
                        </>
                    )}

                    {isAuthenticated && (
                        <>
                            <MenuItemComponent
                                route={{ name: "Listing Info", icon: <MapOutlinedIcon /> }}
                                hasCollapse={true}
                                isOpen={isMyListingOpen}
                                toggleOpen={toggleMyListingMenu}
                                darkMode={darkMode}
                                theme={theme}
                            />
                            <Collapse in={isMyListingOpen} timeout="auto" unmountOnExit>
                                <OnboardingSideMenu
                                    activeScreen={activeScreen}
                                    onMenuClick={onMenuClick}
                                    completionStatus={completionStatus}
                                />
                            </Collapse>
                            <MenuItemComponent route={{ name: "Search", path: "/Search", icon: <SearchIcon /> }} />
                            {filteredNav.map((route, index) => (
                                <MenuItemComponent key={index} route={route} activeScreen={activeScreen} darkMode={darkMode} theme={theme} />
                            ))}
                        </>
                    )}

                </Stack>
            </MenuList>
        </div>
    );
};

export const MobileMenu = ({ activeScreen, onMenuClick }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMyListingOpen, setMyListingOpen] = useState(true);
    const completionStatus = useOnboardingStatus();

    //const userData = JSON.parse(localStorage.getItem("user"));
    const businessData = JSON.parse(localStorage.getItem("business"));
    //console.log(userData, businessData);

    const navigation = useNavigate();
    const auth = useContext(AuthContext);
    const isAuthenticated = auth.token !== "";
    const { darkMode, toggleDarkMode, role_id } = useContext(AuthContext);
    const theme = useTheme();

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const closeMenu = () => setIsMenuOpen(false);
    const toggleMyListingMenu = () => setMyListingOpen(!isMyListingOpen);

    // Shared navigation filtering logic
    const filteredNav = nav.filter((route) => {
        if ((route.path === "/SiteOnboarding" && role_id === 3) ||
            (route.path === "/TrainerOnboarding" && role_id === 2)) {
            return false;
        }
        return route.isMenu && (!isAuthenticated || route.isPrivate);
    });

    return (
        <div key={role_id}>
            {isMenuOpen && <div className="overlay" onClick={closeMenu} />}

            <div className={`right-side-menu ${isMenuOpen ? "open" : ""}`}>
                <div className="menu-header">
                    <div className="logo" onClick={() => {
                        toggleMenu();
                        navigation("/");
                    }}>
                        <img className="noLogLogo"
                            src={darkMode ? require("./../assets/logo_light.png") : require("./../assets/logo_dark.png")}
                            alt="Logo"
                        />

                    </div>

                    <Box sx={{paddingLeft: "16px"}}>
                    <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                    </Box>
                    <div className="close-menu-btn" onClick={toggleMenu}>
                        <FaTimes />
                    </div>
                </div>

                <MenuList className="side-menu-list">
                    <Stack>
                        {isAuthenticated && (
                            <MenuItemComponent route={{ name: "Home", path: "/Home" }} />

                        )}
                        {isAuthenticated && businessData?.slug && (
                            <MenuItem
                                className="mui-menu-item"
                                sx={{
                                    borderRadius: '5em',
                                    width: '95%',
                                    margin: 'auto',
                                    backgroundColor: "transparent",
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover,
                                    }
                                }}
                                onClick={() =>
                                    navigation(`/${businessData?.slug}`)
                                }
                            >
                                <ListItemIcon style={{ minWidth: "40px", color: darkMode ? "#fff" : "#000" }}>
                                    <RocketLaunchIcon fontSize="small" />

                                </ListItemIcon>
                                <ListItemText
                                    primary="My Listing"
                                    primaryTypographyProps={{
                                        style: {
                                            color: theme.palette.text.primary,
                                            fontWeight: "normal",
                                            textAlign: "left"
                                        }
                                    }}
                                />
                            </MenuItem>
                        )}

                        {!isAuthenticated && (
                            <>
                                <MenuItemComponent key="register" route={{ name: "List your Gym", path: "/register", icon: <MapOutlinedIcon /> }} extraClass="reg-btn" />
                                <MenuItemComponent key="login" route={{ name: "Login", path: "/login", icon: <LoginIcon /> }} />
                                <MenuItemComponent route={{ name: "Search", path: "/Search", icon: <SearchIcon /> }} />
                            </>
                        )}

                        {isAuthenticated && (
                            <>
                                <MenuItemComponent
                                    route={{ name: "Listing Info", icon: <MapOutlinedIcon /> }}
                                    hasCollapse={true}
                                    isOpen={isMyListingOpen}
                                    toggleOpen={toggleMyListingMenu}
                                    darkMode={darkMode}
                                    theme={theme}
                                />
                                <Collapse in={isMyListingOpen} timeout="auto" unmountOnExit>
                                    <OnboardingSideMenu
                                        activeScreen={activeScreen}
                                        onMenuClick={onMenuClick}
                                        completionStatus={completionStatus}
                                    />
                                </Collapse>
                                <MenuItemComponent route={{ name: "Search", path: "/Search", icon: <SearchIcon /> }} />
                                {filteredNav.map((route, i) => (
                                    <MenuItemComponent key={i} route={route} activeScreen={activeScreen} darkMode={darkMode} theme={theme} />
                                ))}
                            </>
                        )}

                    </Stack>
                </MenuList>
            </div>
            <MobileFooterMenu menuClick={toggleMenu} />
        </div>
    );
};


export const IsActiveItem = (itemPath) => {
    const location = useLocation(); // Get current route
    const currentPath = location.pathname; // Extract current screen from URL

    if (!itemPath || !currentPath) return ''; // Ensure values exist
    //console.log(itemPath.toLowerCase(), currentPath.toLowerCase(), itemPath.toLowerCase() === currentPath.toLowerCase());
    return itemPath.toLowerCase() === currentPath.toLowerCase() ? 'active-menu-item' : '';
};



export const MenuItemComponent = ({ route, activeScreen, darkMode, hasCollapse, isOpen, toggleOpen, extraClass = "" }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    return (
        <MenuItem
            className={`mui-menu-item ${extraClass}`}
            sx={{
                borderRadius: '5em',
                width: '95%',
                margin: 'auto',
                backgroundColor: IsActiveItem(route.path) ? theme.palette.action.selected : "transparent",
            }}
            onClick={hasCollapse ? toggleOpen : () => navigate(route.path)}
        >
            {/* <div className="menuItem-container">
                {route.icon ? route.icon : <HomeIcon />}
                <p>{route.name}</p>
            </div> */}
            {extraClass !== "reg-btn" && (
                <ListItemIcon style={{ minWidth: "40px", color: theme.palette.mode === 'dark'  ? "#fff" : "#000" }}>
                    {route.icon ? route.icon : <HomeIcon />}
                </ListItemIcon>
            )}
            <ListItemText primary={route.name}
                primaryTypographyProps={{
                    style: {
                        color: extraClass === "reg-btn" ? "#fff" : theme.palette.text.primary,
                        fontWeight: extraClass === "reg-btn" ? 800 : "normal",
                        textAlign: extraClass === "reg-btn" ? "center" : "left"
                    }
                }} />
            {hasCollapse && (
                <ListItemIcon style={{ minWidth: "20px", marginLeft: "auto" }}>
                    {isOpen ? <ExpandMore /> : <ChevronLeftIcon />}
                </ListItemIcon>
            )}
        </MenuItem>
    );
};

export const MobileFooterMenu = ({ activeScreen, menuClick }) => {
    const [value, setValue] = React.useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    // Set the active tab based on current location
    React.useEffect(() => {
        switch (location.pathname) {
            case '/':
                setValue(0);
                break;
            case '/search':
                setValue(1);
                break;
            case '/bookings':
                setValue(3);
                break;
            case '/wallet':
                setValue(4);
                break;
            default:
                setValue(0);
        }
    }, [location.pathname]);

    return (
        <StyledFooter className="mobile-footer">
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
            >
                <BottomNavigationAction
                    label="Home"
                    icon={<HomeIcon />}
                    onClick={() => navigate('/Dashboard')}
                />
                <BottomNavigationAction
                    label="Explore"
                    icon={<SearchIcon />}
                    onClick={() => navigate('/search')}
                />
                <FaviconContainer onClick={menuClick}>
                    <img
                        src="/favicon.ico"
                        alt="Favicon"
                        style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                    />
                </FaviconContainer>
                <BottomNavigationAction
                    label="Bookings"
                    icon={<EventAvailableIcon />}
                    onClick={() => navigate('/bookings')}
                />
                <BottomNavigationAction
                    label="Earnings"
                    icon={<PaidIcon />}
                    onClick={() => navigate('/wallet')}
                />
            </BottomNavigation>
        </StyledFooter>
    );
};

// Styled Footer with CSS grid layout
const StyledFooter = styled('div')(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
    display: 'none', // Hidden by default on non-mobile devices
    '@media (max-width: 640px)': {
        display: 'grid',
    },
}));

// Favicon styling for center alignment
const FaviconContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
