import React from "react";
import { Box } from "@mui/material"; // Use Material-UI's Box for layout
import { useDispatch } from "react-redux"; // Redux hooks
import { removeAuth } from "../../../Services/Redux/Slices/Auth.Slice";
import { removeUser } from "../../../Services/Redux/Slices/User.Slice";
import { Button } from "./Button"; // Import your custom Button

const LogoutButton = () => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    localStorage.removeItem("userTypeSet"); // Clear specific local storage item
    dispatch(removeAuth()); // Dispatch action to remove auth state
    dispatch(removeUser()); // Dispatch action to remove user state

    window.location.reload(); // Refresh the page
  };

  return (
    <Box  sx={{ margin: "20px" }}>
      <Button text="Logout" fill="outline" onClick={handleLogout} />
    </Box>
  );
};

export default LogoutButton;
