import React, { useState, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import { useTheme } from "@mui/material";
// import LocationMarker from "../Common/Misc/LocationMarker";
//import BackButton from "../Common/Buttons/BackButton";

import { spacingStyles } from "../../assets/styles/global";
import { colors } from "../../assets/styles/global"; // Import the colors object or define it if not imported already
import { AspectRatio } from "@mui/icons-material";

import { randomNumber, getRandomMultipleOf5, getRandomMultipleOf500 } from "../../Services/Helpers/RandomNumber";

export default function GymDetailsHeader({ Data, showProfilePic, horizontal = false,
}) {
//console.log(Data);
  const theme = useTheme(); // Access the current theme
  const mode = theme.palette.mode;

  var horBackgroundStyle = {
    backgroundImage: "url(" + Data?.profile?.image + ")",
    height: "108px",
    width: "140px",
    // maxHeight: '96px',
    // width: '6rem',
    borderRadius: "0.5em",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  var vertBackgroundStyle = {
    backgroundImage: "url(" + Data?.profile?.image + ")",
    // height: "40vw",
    aspectRatio: "16/9",
    maxHeight: "360px",
    width: "100%",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "top center",
  };

  const [capacity, setCapacity] = useState();
  const [sqFeet, setSqFeet] = useState();


  useEffect(() => {
    //console.log(Data);
    setCapacity(
      Data.details.space.capacity !== null && Data.details.space.capacity !== "0"
        ? Data.details.space.capacity
        : getRandomMultipleOf5(15, 100)
    );
    setSqFeet(
      Data.details.space.sqFeet !== null &&  Data.details.space.sqFeet !== "0"
        ? Data.details.space.sqFeet
        : getRandomMultipleOf500(500, 2500)
    );
  }, []);


  if (horizontal && Data) {
    return (
      <div className="flexRow " style={{justifyContent: "center"}}>
        {/* <BackButton></BackButton> */}
        <div>
          {showProfilePic ? (
            <div className="gym-hori-img" style={horBackgroundStyle}></div>
          ) : null}
        </div>
        <div
          className="flexCol  "
          style={{ minWidth: 150, paddingLeft: 5 }}
        >
          <h3
            className="h1"
            style={{ ...spacingStyles.mb0, ...spacingStyles.mt0, color: theme.palette.text.secondary }} // Apply textStyle here
          >
            <b>{Data?.profile?.name}</b>
          </h3>
          <div style={{ color: theme.palette.text.primary }}> {/* Apply textStyle here */}
            {/* <LocationMarker showBorder={false} /> */}
            <span className="h2" style={{ color: theme.palette.text.primary }}> {Data?.profile?.location.city}</span>
          </div>

          <div className="flexCol" style={{ ...spacingStyles.mt10 }}>
            {/* <div className="flexRow yellow">
              {[...Array(Data.rating || 0).keys()].map((i) => (
                <div key={i}>
                  <StarIcon />
                </div>
              ))}
            </div>
            <span className="h2 "> {Data?.profile.numRatings || 0} reviews</span> */}
            <div className={`flexRow ${mode === "light" ? 'yellow_dark' : 'yellow'}`}  style={{justifyContent:"center"}}>
              {[...Array(5).keys()].map((i) => (
                <div key={i}>
                  <StarIcon />
                </div>
              ))}
            </div>
            <span className="h2">{Data.ratings.numRatings} reviews</span>


          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ ...spacingStyles.flexRow, }}>
        {/* <BackButton></BackButton> */}
        <div className="centerView">
          {showProfilePic ? (
            <>
              <div style={vertBackgroundStyle}></div>
            </>
          ) : null}
        </div>
        <div
          style={{
            ...spacingStyles.p10,
            ...spacingStyles.flexRow,
            ...spacingStyles.fullWidth,
            ...spacingStyles.alignCenter,
          }}
        >
          <div className="flexCol " >
            <h1
              style={{
                ...spacingStyles.pr10,
                color: theme.palette.text.primary, // Apply textStyle here
                marginTop: 'unset',
                textAlign: 'left',
                textTransform: 'capitalize',
              }}
            >
              {Data?.profile.name}
            </h1>
            {/* <div className="flexRow yellow">
              {!!Data?.profile.rating &&
                [...Array(Data.rating).keys()].map((i) => (
                  <div key={i}>
                    <StarIcon />
                  </div>
                ))}
            </div>
            <span className="h2 white"> {Data?.profile.numRatings || 0} reviews</span> */}
            <div className="flexRow yellow">
              {[...Array(5).keys()].map((i) => (
                <div key={i}>
                  <StarIcon />
                </div>
              ))}
            </div>
            <div
              className="flexRow white"
              style={{ color: theme.palette.text.primary, justifyContent: "space-between",...spacingStyles.pb5}} // Apply textStyle here
            >
              {/* <LocationMarker showBorder={false} /> */}
              <span className="h2 left" style={{...spacingStyles.ml5}} >{Data.ratings.numRatings} reviews</span>
              <span className="h2"> {Data?.profile.location.city}</span>
            </div>

            {/* Capcity Info */}
            <div
              className="flexRow centerView"
              style={{
                ...spacingStyles.flexAround,
                ...spacingStyles.w100,
                justifyContent: "flex-end"
              }}
            >
              <div className="flexRow" style={{ ...spacingStyles.pr10 }}>
                <div className="flexRow">
                  {/* <Ionicons name="resize" color={spacingStyles.gray.color} size={20} /> */}
                  <span className="h3" style={{ fontWeight: "500", color: theme.palette.text.secondary }}>
                    Size:
                  </span>
                </div>
                <span
                  style={{ color: theme.palette.text.secondary, fontWeight: "500", marginLeft: 5 }}
                >
                  {sqFeet} sq ft
                </span>
              </div>
              <div className="flexRow" style={{ color: theme.palette.text.secondary, fontWeight: "500" }}>
                <div className="flexRow "  >
                  {/* <Ionicons name="people" color={spacingStyles.gray.color} size={20} /> */}
                  <span>
                    Capacity:
                  </span>
                </div>
                <span>
                  {capacity} people
                </span>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}
