import axios from "axios";
import config from "../../config";
import Store from "../Redux/Store";
import { addUser, setUserToken } from "../Redux/Slices/User.Slice";
import { createAuth, addAuthRoleID } from "../Redux/Slices/Auth.Slice";
import { addBusiness } from "../Redux/Slices/Business.Slice";
import { addAddress } from "../Redux/Slices/Location.Slice";
import { setSchedule } from "../Redux/Slices/Schedule.Slice";
import { setSpace, setAmenities, setSpaceType, setFloorCoverings, setEquipment, setExtras } from "../Redux/Slices/Onboarding.Slice";
import { fetchMyGalleryAsync } from "../Redux/Slices/Gallery.Slice";

export const getLoginData = async (token) => {

  const response = await axios.get(config.apiUrl + `auth/getLogData/${token}`, {
    params: {
      token,
    },
  });

  //console.log(response);

  // Set user data
  if (response?.data?.token && response?.data?.userData) {
   
    localStorage.setItem("token", response.data.token);
    const userInfo = response.data.userData;
    await Store.dispatch(addUser(response.data.userData));
    await Store.dispatch(setUserToken(response.data.token));

    let roleType = null;
    switch (userInfo.role_id) {
      case "1":
        roleType = "client";
        await Store.dispatch(addAuthRoleID(userInfo.role_id));
        break;
      case "2":
        roleType = "facility";
        await Store.dispatch(addAuthRoleID(userInfo.role_id));
        break;
      case "3":
        roleType = "trainer";
        await Store.dispatch(addAuthRoleID(userInfo.role_id));
        break;
      default:
        break;
    }

    // Set role type
    await Store.dispatch(
      createAuth({
        role_id: roleType ? userInfo.role_id : null,
        token,
        roleType: roleType,
      })
    );
  }

  // Set business data
  if (response?.data?.businessData && response?.data?.businessData) {
    await Store.dispatch(addBusiness(response.data.businessData));
  }

  // Set space data
  //console.log(response.data.spaceData);
  if (response?.data?.spaceData && response?.data?.spaceData) {
    await Store.dispatch(setSpace(response.data.spaceData.space));
    await Store.dispatch(setSpaceType(response.data.spaceData.spaceType));
    await Store.dispatch(setFloorCoverings(response.data.spaceData.floorCovering));
    await Store.dispatch(setEquipment(response.data.spaceData.equipment));
    await Store.dispatch(setAmenities(response.data.spaceData.amenities));
    await Store.dispatch(setExtras(response.data.spaceData.extras));
    await Store.dispatch(setExtras(response.data.spaceData.extras));
  }

  // Set business data
  if (response?.data?.scheduleData && response?.data?.scheduleData) {
    await Store.dispatch(setSchedule(response.data.scheduleData));
  }

  // Set location data
  if (response?.data?.locationData) {
    let locationData = response?.data?.locationData;
    locationData = {
      ...locationData,
      lat: Number(locationData.lat) || 0,
      lng: Number(locationData.lng) || 0,
      radius: Number(locationData.radius) || 0,
    };
    await Store.dispatch(addAddress(locationData));
  }

  await Store.dispatch(fetchMyGalleryAsync({ token }))

  return response;
};

export const updateLocationLink = async (data, token) => {
  const response = await axios.post(config.apiUrl + "profile/updateLocation", {
    data,
    token: token,
  });
  return response;
};

export const updateBusiness = async (data, token) => {
  const response = await axios.post(config.apiUrl + "profile/updateBusiness", {
    data,
    token: token,
  });
  return response;
};

export const updateContact = async (data, token) => {

  //console.log(data); 
  const response = await axios.post(config.apiUrl + "profile/updateContact", {
    data,
    token: token,
  });
  return response;
};

export const updateRole = async (role_id, token) => {
  let url = JSON.stringify(
    config.apiUrl + "profile/updateRole/" + role_id + "/" + token
  );
  let cleanurl = url.replace(/"/g, "");

  const response = await axios.get(cleanurl);
  return response;
  //return cleanurl;
};

export const updateProfilePic = async (picture_id, token) => {
  console.log("Update Picture URl"); 
  let url = JSON.stringify(
    config.apiUrl + "profile/updateProfileImg/" + picture_id + "/" + token
  );
  let cleanurl = url.replace(/"/g, "");

  const response = await axios.get(cleanurl);
  return response;
};

export const updateMatchMe = async ({ token, data, lat, long }) => {
  const params = {
    token,
    ...data,
  };

  console.log("..params", JSON.stringify(params));

  const response = await axios.post(
    config.apiUrl + `matchme/matchMeUpdateTrainer/${lat}/${long}`,
    params
  );
  return response;
};

export const updateSpaceDetails = async (data, token) => {
  const response = await axios.post(config.apiUrl + "profile/updateSpace/", {
    data,
    token: token,
  });
  return response;
};
export const addTermAgreementRequest = async (data, token) => {
  const response = await axios.post(config.apiUrl + "profile/addTermAgreementRequest/", {
    data,
    token: token,
  });
  return response;
};


export const updateProfilePicture = async (picture_id, token) => {
  const response = await axios.get(
    config.apiUrl + `profile/updateProfileImg/${picture_id}/${token}`,
    {
      params: {
        token,
      },
    }
  );
  return response;
};

export const getMyGallery = async ({ token }) => {
  const response = await axios.post(
    config.apiUrl + `profile/getMyGallery/${token}`
  );
  return response;
};

export const deletePhoto = async ({ picture_id, token }) => {
  const response = await axios.post(
    config.apiUrl + `Upload/deletePhoto/${picture_id}/${token}`
  );
  return response;
};
