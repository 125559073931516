import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Typography } from "@mui/material"; // Import MUI components
import MapboxCircle from "mapbox-gl-circle"; // Import MapboxCircle
import { Button } from "../Common/Buttons/Button";
import config from "../../config";
import useStateRef from "react-usestateref";
import { GetTimeZone } from "../../Services/Helpers/GetTimeZone";
import { updateLocationLink } from "../../Services/APIHandlers/ProfileApi";
import { addAddress } from "../../Services/Redux/Slices/Location.Slice";
import { useTheme } from '@mui/material/styles';
import { colors, globalStyles, formStyles } from "../../assets/styles/global";

const OnboardingLocation = ({ radiusOpt = true, setSubmitDisabled }) => {
  const [isLoading, setLoading] = useState(false);
  if (isLoading) return null;
  return <MapWithSearchBox radiusOpt={radiusOpt} setSubmitDisabled={setSubmitDisabled} />;
};

const MapWithSearchBox = ({ radiusOpt, setSubmitDisabled }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const geocoderContainerRef = useRef(null);
  const markerRef = useRef(null);
  const myCircleRef = useRef(null);
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [isAddressSet, setIsAddressSet] = useState(false); // New state to track if address is set
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const location = useSelector((state) => state.location.address);
  const [locationData, setLocationData, locationDataRef] = useStateRef(location);
  const [geoAddress, setGeoAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
  });

  const [addressSuite, setAddressSuite] = useState(false);

  useEffect(() => {
    if (!location && setSubmitDisabled) {
      setSubmitDisabled(true)
    }
  }, [location])
  //console.log(config.mapBoxToken);
  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: 'pk.eyJ1IjoiaG9wcGVyZml0IiwiYSI6ImNsa2tpM2c0ZzA2dWkzcG1tNnV3MmVyMjQifQ.x9vQVrNmILfTln-SAXS8pw',
      mapboxgl: mapboxgl,
      marker: false,
      countries: 'US',
      bbox: [-179.9, 18.0, -66.9, 72.0],
      placeholder: 'Search USA Address',
    });

    // Clear existing Geocoder
    if (geocoderContainerRef.current) {
      const existingGeocoder = geocoderContainerRef.current.querySelector(".mapboxgl-ctrl-geocoder");
      if (existingGeocoder) {
        geocoderContainerRef.current.removeChild(existingGeocoder);
      }
    }

    // Append Geocoder to the container
    if (geocoderContainerRef.current) {
      geocoderContainerRef.current.appendChild(geocoder.onAdd(mapRef.current));
    }

    //Apply MUI styles to the Geocoder input field
    if (geocoderContainerRef.current) {
      const geocoderInput = geocoderContainerRef.current.querySelector(".mapboxgl-ctrl-geocoder--input");
      if (geocoderInput) {
        geocoderInput.style.color = theme.palette.text.primary;
      }
    }

    geocoder.on("result", (e) => {
      const locationData = locationDataRef.current;
      console.log(e);
      if (mapRef.current) {
        mapRef.current.flyTo({
          center: e.result.center,
          essential: true,
        });
      }

      if (markerRef.current) {
        markerRef.current.remove();
      }

      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat(e.result.center)
        .addTo(mapRef.current);

      if (myCircleRef.current) {
        myCircleRef.current.setCenter({
          lat: e.result.center[1],
          lng: e.result.center[0],
        });
      }

      setLocationData({
        ...locationData,
        address: e.result.place_name,
        lat: e.result.center[1],
        lng: e.result.center[0],
      });

      setGeoAddress({
        address: e.result.place_name || "",
        city: e.result.context.find((c) => c.id.includes("place"))?.text || "",
        state: e.result.context.find((c) => c.id.includes("region"))?.text || "",
        zip: e.result.context.find((c) => c.id.includes("postcode"))?.text || "",
      });

      setIsLocationChanged(true);
      setIsLocationSelected(true);

      if (setSubmitDisabled) {
        setSubmitDisabled(true)
      }
    });
  }, [theme, locationDataRef, setLocationData]);

  useEffect(() => {
    const locationData = locationDataRef.current;

    const mapStyle = theme.palette.mode === "dark"
      ? "mapbox://styles/mapbox/dark-v10"
      : "mapbox://styles/mapbox/streets-v11";

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapStyle,
      center: [locationData?.lng ?? -74.5, locationData?.lat ?? 40],
      zoom: 13,
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl());

    mapRef.current.on("load", () => {
      if (markerRef.current) {
        markerRef.current.remove();
      }

      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat([locationData?.lng ?? -74.5, locationData?.lat ?? 40])
        .addTo(mapRef.current);

      if (radiusOpt && locationData.lat && locationData.lng) {
        myCircleRef.current = new MapboxCircle(
          { lat: locationData.lat, lng: locationData.lng },
          locationData.radius ? locationData.radius * 1609.34 : 1,
          {
            editable: false,
            minRadius: 10,
            fillColor: "#29AB87",
          }
        ).addTo(mapRef.current);
      }
    });

    mapRef.current.on("click", (e) => {
      if (isLocationSelected) {
        return;
      }

      const locationData = locationDataRef.current;

      if (markerRef.current) {
        markerRef.current.remove();
      }

      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat(e.lngLat)
        .addTo(mapRef.current);

      if (myCircleRef.current) {
        myCircleRef.current.setCenter({
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
        });
      }

      setLocationData({
        ...locationData,
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });

      setGeoAddress({
        address: "Selected Location",
        city: "",
        state: "",
        zip: "",
      });


      setIsLocationChanged(true);
    });
  }, [locationDataRef, setLocationData, theme.palette.mode, radiusOpt, isLocationSelected]);

  const onRadiusChange = (e) => {
    const newRadius = parseFloat(e.target.value);
    if (!isNaN(newRadius)) {
      myCircleRef.current.setRadius(newRadius * 1609.34);
      setLocationData({
        ...locationData,
        radius: e.target.value,
      });
    } else {
      setLocationData({
        ...locationData,
        radius: 0,
      });
    }
  };

  const onSuiteChange = (e) => {
    console.log(e.target.value);
    setAddressSuite(e.target.value);
  };

  const handleSubmit = async () => {
    const locationData = locationDataRef.current;
    setIsLocationChanged(false);

    try {
      const timeZone = await GetTimeZone({
        latitude: locationData.lat ?? 33,
        longitude: locationData.lng ?? 33,
      });

      const data = [
        {
          address: geoAddress.address || locationData.address || "",
          lat: locationData.lat ? locationData.lat : 33,
          lng: locationData.lng ? locationData.lng : 33,
          radius: locationData.radius ? locationData.radius : "",
          city: geoAddress.city || locationData.city || "",
          state: geoAddress.state || locationData.state || "",
          suite: addressSuite || "",
          timezone: timeZone.data?.timeZoneName,
          timeZoneID: timeZone.data?.timeZoneId,
        },
      ];

      const response = await updateLocationLink(JSON.stringify(data), token);
      if (response.data.success) {
        dispatch(addAddress(data[0]));

        setSubmitDisabled(false)
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box sx={{ ...globalStyles.w100, padding: 2 }}>
      <Box ref={geocoderContainerRef} sx={{ marginBottom: 2 }} />
      {isLocationChanged && (
        <Box sx={{ justifyContent: "flex-start", display: "flex", paddingBottom: "20px" }} >
          <TextField
            variant="outlined"
            placeholder="Apt, Suite, etc. (optional)"
            onChange={onSuiteChange}
            sx={{ ...formStyles.textField, alignItems: "flex-start" }}
          />
        </Box>
      )}
      <Box
        ref={mapContainerRef}
        sx={{
          height: "25vh",
          width: "100%",
          borderRadius: "10px",
          overflow: "hidden",
          marginBottom: 3,
        }}
      />

      {radiusOpt && (
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" color="white">
            Radius:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={locationData?.radius || ""}
            placeholder="Driving Radius"
            onChange={onRadiusChange}
            sx={{
              input: { color: theme.palette.text.primary },
              backgroundColor: theme.palette.background.default,
              borderRadius: "4px",
            }}
          />
        </Box>
      )}

      <Box sx={{ marginTop: 3, maxWidth: '440px', margin: '0 auto' }}>
        {geoAddress.address && isLocationChanged && (
          <Typography sx={{ color: theme.palette.text.primary }} variant="body1">
            <b>New Address:</b> {geoAddress.address}
          </Typography>
        )}
        {locationData && !isLocationChanged && (
          <Typography sx={{ color: theme.palette.text.primary }} variant="body1">
            <b>Current Address:</b> {locationData.address}
          </Typography>
        )}
        <Button
          fill={isLocationChanged ? "solid" : "outline"}
          disabled={!isLocationChanged}
          text="Set this Address"
          onClick={handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default OnboardingLocation;
