// src/Home.js
import React, { useState, useEffect, useContext, memo, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";

import GymDetailsHeader from "../../Components/Gym/GymDetailsHeader";

import { spacingStyles, colors, formStyles } from "../../assets/styles/global";

import moment from "moment";

//import { selectDate, selectTime } from '../../Services/Redux/Slices/Booking.Slice.js';

import StripePayment from "../../Components/Forms/StripePayment";
import AuthContext from "../../Navigation/AuthWrapper";

import RegisterForm from "../../Components/Forms/RegisterForm";
import LoginForm from "../../Components/Forms/LoginForm";
import OTPForm from "../../Components/Forms/OTPForm";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { addBooking } from "../../Services/APIHandlers/BookingApi";

import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { ContentPasteSearchOutlined } from "@mui/icons-material";

export const Checkout = memo(({ isOpen, mode }) => {

  //console.log(mode); 

  const selectedDate = useSelector((state) => state.booking.selectedDate, shallowEqual);
  const selectedTime = useSelector((state) => state.booking.selectedTime, shallowEqual);
  const selectedSite = useSelector((state) => state.booking.selectedSite, shallowEqual);

  //console.log(selectedSite.chargeRate);

  const time24Hour = moment(selectedTime.time, "HH:mm:ss");
  const time12Hour = time24Hour.format("hh:mm a");

  //console.log(isAuthenticated);

  const [isRegistered, setIsRegistered] = useState(false);
  const [isOTPConfirmed, setIsOTPConfirmed] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [authToggle, setAuthToggle] = useState("Login");
  const [bookingID, setBookingID] = useState(null);

  const [chargeRate, setChargeRate] = useState(Number(selectedSite.details.space.chargeRate));
  const [bookingFEE, setBookingFEE] = useState(500);

  const auth = useContext(AuthContext);
   const checkoutRef = useRef(null);

  const textStyle =
    mode === "light"
      ? { color: colors.black, inputStyles: formStyles.input_light }
      : { inputStyles: formStyles.input };

  useEffect(() => {
    checkoutRef.current.scrollIntoView({ behavior: "smooth" });
  }, [isOpen])

useEffect(() => {
  setIsLogged(auth.token !== "");
  setIsOTPConfirmed(auth.token !== "");
}, [auth.token]);

useEffect(() => {

  const handleBookingRequest = async () => {
    //console.log(selectedSite);
    const data = {
      bookingTime: selectedTime,
      bookingDate: selectedDate,
      slug: selectedSite.profile?.slug,
    };

    //console.log(data);
    try {
      const response = await addBooking(data, auth.token);

      const result = JSON.parse(response.data.result);
      setBookingID(result.BookingID);
      //console.log(result);

    } catch (err) {
      console.error(err);
    }
  };
  if (isLogged && isOTPConfirmed) {
    handleBookingRequest();
  }

}, [isLogged, isOTPConfirmed, isOpen]);

// useEffect(() => { console.log("isRegistered: ", isRegistered); }, [isRegistered]);
// useEffect(() => { console.log("isOTPConfirmed updated:", isOTPConfirmed); }, [isOTPConfirmed]);
// useEffect(() => { console.log("isLogged useEffect: ", isLogged); }, [isLogged]);
// useEffect(() => { console.log("bookingID: ", bookingID); }, [bookingID]);


const handleRegistrationSuccess = () => {
  //console.log("setIsRegistered: ", true);
  setIsRegistered(true);
};

const handleOTPSuccess = () => {
  setIsOTPConfirmed(true);
};

const handleAuthToggle = (value) => {
  // This function will be called when a button is clicked
  // console.log("Button clicked with value:", value);
  setAuthToggle(value);
};

const theme = useTheme();

function calculateCharge() {
  // console.log(chargeRate, bookingFEE)
  const $totalCharge = (chargeRate + bookingFEE) / 100;
  return $totalCharge; // Format to two decimal places
}

return (
  <div >
    <div ref={checkoutRef}></div>
    <Box sx={{ ...spacingStyles.mb20h, ...spacingStyles.pb20 }}>
      <Box sx={{ ...spacingStyles.mauto, mt: 6 }}>
        <div className="centerView">
          <GymDetailsHeader
            Data={selectedSite}
            showProfilePic={true}
            horizontal={true}
            mode={mode}
          />
        </div>
        <div className="summary-box">
          <h1
            style={{
              ...spacingStyles.munset,
              ...spacingStyles.pt20,
              ...spacingStyles.pb10,
              ...colors.black,
            }}
          >
            Your Booking
          </h1>
          <div
            className="flexRow spaceBtw"
            style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}
          >
            <span style={{ ...colors.black }} className="p">
              Date
            </span>
            <span style={{ ...colors.black }}>
              {moment(selectedDate).format("LL")}
            </span>
          </div>
          <div
            className="flexRow spaceBtw"
            style={{
              ...spacingStyles.p10,
              ...spacingStyles.plr20,
              ...colors.black,
            }}
          >
            <span className="p" style={{ ...colors.black }}>
              Time
            </span>
            <span style={{ ...colors.black }}>{time12Hour}</span>
          </div>
          <div style={{ ...spacingStyles.pt40 }}></div>
          {/* <div
              className="flexRow spaceBtw"
              style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}
            >
              <span style={{ ...colors.black }} className="p">
                1 Month Membership
              </span>
              <span className="strike-thru">$14.99</span>
            </div> */}
          <div
            className="flexRow spaceBtw"
            style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}
          >
            <span style={{ ...colors.black }}>Hopper Booking</span>
            <span style={{ ...colors.black }}>${(Number(selectedSite.details.space.chargeRate) / 100).toFixed(2)}</span>
          </div>
          <div
            className="flexRow spaceBtw"
            style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}
          >
            <span style={{ ...colors.black }}>Technology Fee</span>
            <span style={{ ...colors.black }}>${(bookingFEE / 100).toFixed(2)}</span>
          </div>
          {/* <div className="flexRow spaceBtw" style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}><span>Tax</span><span>$0.00</span></div> */}
          <div className="flexCol">
            <h2
              style={{
                ...spacingStyles.munset,
                ...spacingStyles.pt20,
                ...textStyle.color,
              }}
            >
              Total Amount
            </h2>
            <h1
              // className="green"
              style={{
                ...spacingStyles.munset,
                ...spacingStyles.pt10,
                ...textStyle.color,
              }}
            >
              $ {calculateCharge().toFixed(2)}
            </h1>
          </div>

          {/* <p> This fee covers the cost of membership, which grants access to exclusive benefits and services. By displaying it in the cart, we aim to provide transparent information and make the process of becoming a member seamless for your convenience.</p> */}
        </div>

        {/* {isLogged &&
          <h2>EasyPay</h2>
        } */}
      </Box>
      <>
        {!isRegistered && !isLogged && (
          <div style={{ ...spacingStyles.pt40 }}>
            {/* <h2>Already Registered?</h2> */}
            <div style={{ ...spacingStyles.mauto }}>
              <h1 style={{ ...colors.black }}>Reserve now.</h1>
              <ButtonGroup
                sx={{ width: "100%" }}
                color="primary"
                variant="outlined"
                size="large"
                aria-label="outlined primary button group"
              >
                {/* Register Button */}
                <Button
                  variant="outlined"
                  sx={{
                    ...(mode === "light"
                      ? localStyles.toggle_right_light
                      : localStyles.toggle_right),
                    // Apply background color based on the selected toggle
                    backgroundColor:
                      authToggle === "Register" ? "#ff6d0021" : "transparent",
                    border:
                      authToggle === "Register"
                        ? colors.primary_50
                        : colors.primary_50,
                    color: authToggle === "Register" ? colors.black : "",
                    fontWeight: "Register" ? "bold" : "",
                  }}
                  onClick={() => handleAuthToggle("Register")}
                >
                  Register
                </Button>

                {/* Login Button */}
                <Button
                  sx={{
                    ...(mode === "light"
                      ? localStyles.toggle_left_light
                      : localStyles.toggle_left),
                    // Apply background color based on the selected toggle
                    backgroundColor:
                      authToggle === "Login" ? "#ff6d0021" : "transparent",
                    border:
                      authToggle === "Login"
                        ? colors.primary_50
                        : colors.primary_50,
                    // color: authToggle === "Login" ? colors.black : "",
                    fontWeight: "Login" ? "bold" : "",
                  }}
                  onClick={() => handleAuthToggle("Login")}
                >
                  Login
                </Button>
              </ButtonGroup>
            </div>
            <div style={{ ...spacingStyles.pt5h }}>
              {authToggle === "Login" && (
                <LoginForm
                  onSuccess={handleRegistrationSuccess}
                  mode={mode}
                />
              )}
              {authToggle === "Register" && (
                <RegisterForm
                  onSuccess={handleRegistrationSuccess}
                  mode={mode}
                />
              )}
            </div>
          </div>
        )}
        {isRegistered && !isOTPConfirmed && (
          <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>
            <OTPForm onSuccess={handleOTPSuccess} />
          </div>
        )}{" "}
        {isLogged && bookingID !== null && (

          <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>

            <StripePayment
              bookingID={bookingID}
              isOpen={isOpen}
              mode={mode}
            />
          </div>
        )}
      </>
      <Box sx={{ ...spacingStyles.mauto, mt: 6 }}>
        <Typography
          variant="h6"
          sx={{ color: "gray", textAlign: "left", mb: 2 }}
        >
          Cancellation Policy
        </Typography>
        <Typography variant="body1" sx={{ color: "gray", textAlign: "left" }}>
          HopperSites can cancel or postpone bookings. HopperSites must
          respond to appointment requests within 12 hours prior to the booking
          start time; otherwise, it's auto-cancelled with full refund or
          credit. Hoppers can cancel within 12 hours for valid reasons.
        </Typography>
      </Box>

      <Box sx={{ ...spacingStyles.mauto, mt: 4 }}>
        <Typography
          variant="h6"
          sx={{ color: "gray", textAlign: "left", mb: 2 }}
        >
          Rules and Regulations
        </Typography>
        <Typography variant="body1" sx={{ color: "gray", textAlign: "left" }}>
          Certified trainers only. One client accompanies a Hopper per booking
          session. Both client and Hopper must sign a waiver. Hoppers are
          responsible for cleanup; low cleanliness ratings lead to membership
          revocation. Punctuality is mandatory.
        </Typography>
      </Box>

      <Box sx={{ ...spacingStyles.mauto, mt: 4 }}>
        <Typography
          variant="h6"
          sx={{ color: "gray", textAlign: "left", mb: 2 }}
        >
          Subscription Policy
        </Typography>
        <Typography variant="body1" sx={{ color: "gray", textAlign: "left" }}>
          Exclusive Subscription Access: Bookings available only to subscribed
          Hoppers. Enjoy hassle-free subscriptions with us—cancel anytime
          without any extra fees. We value simplicity and convenience, making
          your experience with us straightforward and stress-free.
        </Typography>
      </Box>
    </Box>
  </div>
);
})

const localStyles = {
  toggle_left: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "white",
      //backgroundColor: 'darkblue',
    },
  },
  toggle_right: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "white",
      //backgroundColor: 'gray',
    },
  },
  toggle_left_light: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.black,
    "&:hover": {
      color: "black",
      //backgroundColor: 'darkblue',
    },
  },
  toggle_right_light: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.black,
    "&:hover": {
      color: "black",
      //backgroundColor: 'gray',
    },
  },
};
