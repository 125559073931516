import axios from "axios";
// import dotenv from 'dotenv';
// dotenv.config();
import config from '../../config';

export const LoginHandler = async (email) => {
  const response = await axios.get(config.apiUrl + "auth/checkEmail/" + email);
  return response;
};

export const RegisterHandler = async (payload) => {
  //console.log(config.apiUrl); 
  const response = await axios.post(config.apiUrl + "auth/verify_register", JSON.stringify(payload));
  return response;
};

export const verifyLogin = async (email) => {
  const response = await axios.post(config.apiUrl + 'auth/verify_login/' + email)
  return response
}

export const verify_SMSPin = async (pin, token) => {
  const response = await axios.get(config.apiUrl + 'auth/login_otp_sms/' + pin + '/' + token )
  return response
}

export const verify_EmailPin = async (pin, token) => {
  const response = await axios.get(config.apiUrl + 'auth/login_otp_email/' + pin + '/' + token )
  return response
}

export const emailOTP = async (token) => {
  const response = await axios.get(config.apiUrl + 'auth/verify_email/' + token)
  return response
};

export const textOTP = async (token) => {
  const response = await axios.get(config.apiUrl + 'auth/verify_sms/' + token)
  return response
};
