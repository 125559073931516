import React, { useMemo, useState } from "react";
import { BsEnvelope, BsThreeDotsVertical } from "react-icons/bs";
import { FaCircle, FaCheck } from "react-icons/fa";
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import calendarReschedule from "../../assets/calendar-reschedule.png";
import getErrorMessage from "../../Services/Helpers/getErrorMessage";
import moment from "moment";

import SlideInPanel from "../Modals/slideInPanel";
import TrainerRating from "../Forms/TrainerRating";


const PreviousCard = ({ bookingData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuVisible = Boolean(anchorEl);
  const theme = useTheme();

  const [isPanelOpen, setPanelOpen] = useState(false);
  const openPanel = () => {
    setPanelOpen(true);
  };
  const closePanel = () => {
    setPanelOpen(false);
  };

  console.log(bookingData);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const time = useMemo(() => {
    let result = "";

    if (bookingData.booking.start_time) {
      result += moment(bookingData.booking.start_time).format("hh:mma");

      if (bookingData.booking.end_time) {
        result += " - " + moment(bookingData.booking.end_time).format("hh:mma");
      }
    }

    return result;
  }, [bookingData]);

  const timeRemaining = useMemo(() => {
    const { start_time } = bookingData.booking;

    if (!start_time) return "";

    const t = moment(start_time).diff(moment(), "hours");

    return t > 0 ? t + " Hrs Remaining" : "";
  }, [bookingData]);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          p: 2,
          boxShadow: theme.shadows[1],
        }}
      >
        {/* First Row: Time and Menu */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="body1"
            color={theme.palette.text.primary}
            sx={{ textAlign: "left", pl: 2 }}
            fontWeight={600}
          >
            {time}
          </Typography>

          <Box display="flex" alignItems="center">
            {!!timeRemaining && (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                sx={{
                  backgroundColor: theme.palette.background.default,
                  borderRadius: 1,
                  p: "2px 5px",
                }}
              ></Box>
            )}

            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              fontWeight={500}
              mr={1}
            >
              completed
            </Typography>
            <FaCheck color={theme.palette.success.main} size={13} />

            <IconButton onClick={handleMenuOpen}>
              <BsThreeDotsVertical
                color={theme.palette.text.primary}
                size={25}
                style={{ marginLeft: 5 }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={menuVisible}
              onClose={handleMenuClose}
              PaperProps={{
                sx: { borderRadius: 2, mt: 1 },
              }}
            >
              <MenuItem onClick={handleMenuClose}>
                <img
                  src={calendarReschedule}
                  style={{ width: 18, height: 18, marginRight: 10 }}
                  alt="Calendar Reschedule"
                />
                Reschedule
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <BsEnvelope
                  size={18}
                  color={theme.palette.text.primary}
                  style={{ marginRight: 10 }}
                />
                Message
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        {/* Gym and Location */}
        <Typography
          variant="body2"
          color={theme.palette.text.primary}
          sx={{ textAlign: "left", pl: 2 }}
          fontWeight={600}
        >
          {bookingData.space.name}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          sx={{ textAlign: "left", pl: 2 }}
        >
          {bookingData.location.address}
        </Typography>

        {/* Bottom Section: User Info and Actions */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          mt={2}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ textAlign: "left", pl: 1 }}
          >
            <Avatar
              src={bookingData.booking_user?.image}
              alt={`${bookingData.booking_user?.name}`}
            />
            <Typography variant="body2" fontWeight={500}>
              {bookingData.space?.name}
            </Typography>
          </Box>

          <Box display="flex" gap={2}>
            <Button
              onClick={openPanel}
              variant="contained"
              sx={{
                backgroundImage: theme.palette.gradient.main,
                color: theme.palette.common.black,
                fontWeight: 500,
              }}
            >
              Leave-Review
            </Button>
          </Box>
        </Box>
      </Box>
      <SlideInPanel
        isOpen={isPanelOpen}
        closePanel={closePanel}
        mode={theme.palette.mode}
      >
        <TrainerRating bookingUser={bookingData.booking_user} />
      </SlideInPanel>
    </div>

  );
};

export default PreviousCard;
