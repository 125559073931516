import React, { useState , useContext} from "react";
import { validateFormData } from "../../Services/Forms/Validations";
import { RegisterHandler } from "../../Services/APIHandlers/AuthApi";
import { useSelector, useDispatch } from "react-redux";
import { setPending } from "../../Services/Redux/Slices/Auth.Slice";
import { addPhone, addEmail } from "../../Services/Redux/Slices/User.Slice";
import { Button } from "../Common/Buttons/Button";
import { Box, TextField, Typography, Checkbox, FormControlLabel, InputAdornment, IconButton, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import formatPhoneNumber from "../../Services/Helpers/FormatPhoneNumber";
import { formStyles } from "../../assets/styles/global"; // Import formStyles
import AuthContext from "../../Navigation/AuthWrapper";
import { useTheme } from "@mui/material";

const RegisterForm = ({ onSuccess, mode = "dark", userType= "New User"}) => {
  const { darkMode } = useContext(AuthContext);
  const email = useSelector((state) => state.user.email);
  const dispatch = useDispatch();

  const theme = useTheme(); 

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState({
    country_phone: {
      country: {
        code: "",
        iso: "",
        name: "",
        sample_phone: "",
      },
      phone: 0,
    },
    email: "",
    lastname: "",
    name: "",
    terms: true,
    role_id: null,
  });

  // Centralized styles object
  const styles = {
    container: {
      maxWidth: "sm",
      margin: "0 auto",
      textAlign: "left",
    },
    title: {
      // marginBottom: "10px",
      color: theme.palette.text.primary
    },
    description: {
      color: theme.palette.text.secondary,
      marginBottom: "30px",
    },
    input: {
      ...formStyles[mode === "light" ? "input_light" : "input"], // Use formStyles for inputs
    },
    errorText: {
      ...formStyles.errorText, // Use formStyles for error text
      marginBottom: "10px",
    },
    checkbox: {
      color: mode === "light" ? "black" : "white",
      marginTop: "5px",
    },
    buttonContainer: {
      marginTop: "15px",
    },
    popover: {
      backgroundColor: "yellow",
      color: mode === "light" ? "black" : "black",
    },
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!userData.terms) {
      setFormErrors({ ...formErrors, terms: true });
      setLoading(false);
      return;
    }

    const { lastname, name, email } = userData;
    const phone = String(userData.country_phone.phone);
    const validationData = {
      email: { email: true, required: true },
      name: { required: true },
      lastname: { required: true },
      phone: { required: true, minlength: 10 },
    };

    const validated = validateFormData(validationData, {
      email,
      name,
      lastname,
      phone,
    });

    if (!validated.isValid) {
      setLoading(false);
      setFormErrors(validated.errors);
      return;
    } else {
      setFormErrors({});
      try {
        const response = await RegisterHandler(userData);
        if (!response.data?.error) {
          dispatch(setPending(response?.data?.verify_token));
          onSuccess();
        } else {
          setFormErrors({ emailInUse: "Email already in use" });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={styles.container}>
      <img
        className="welcomeLogo"
        src={darkMode ? require("../../assets/logo_light.png") : require("../../assets/logo_dark.png")}
        alt="Logo"
      />
      <Typography variant="h5" sx={styles.title}>
        {`${userType} Registration`}
      </Typography>

      {!Object.keys(formErrors).length && (
        <Typography sx={styles.description}>
          Register here and verify your account.
        </Typography>
      )}

      <TextField
        label="First Name"
        fullWidth
        variant="outlined"
        error={!!formErrors?.name}
        sx={{ marginBottom: "10px" }}
        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
      />
      {formErrors?.name && (
        <Typography sx={styles.errorText}>Please enter your first name</Typography>
      )}

      <TextField
        label="Last Name"
        fullWidth
        variant="outlined"
        error={!!formErrors?.lastname}
        sx={{ marginBottom: "10px" }}
        onChange={(e) => setUserData({ ...userData, lastname: e.target.value })}
      />
      {formErrors?.lastname && (
        <Typography sx={styles.errorText}>Please enter your last name</Typography>
      )}

      <TextField
        label="Email"
        fullWidth
        variant="outlined"
        error={!!formErrors?.email || !!formErrors?.emailInUse}
        sx={{ marginBottom: "10px" }}
        value={userData.email}
        onChange={(e) => {
            const value = e.target.value;
            dispatch(addEmail(value));
            setUserData({ ...userData, email: value })
          }
        }
      />
      {formErrors?.email && (
        <Typography sx={styles.errorText}>Please enter a valid email</Typography>
      )}
      {formErrors?.emailInUse && (
        <Typography sx={styles.errorText}>{formErrors.emailInUse}</Typography>
      )}

      <TextField
        label="Phone Number"
        fullWidth
        variant="outlined"
        error={!!formErrors?.phone}
        sx={{ marginBottom: "10px" }}
        value={formatPhoneNumber(number)}
        onChange={(e) => {
          const value = e.target.value;
          setNumber(value);
          dispatch(addPhone(value));
          setUserData({
            ...userData,
            country_phone: {
              ...userData.country_phone,
              phone: Number(value.replace(/\D/g, "")),
            },
          });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-describedby={id} onClick={handleInfoClick}>
                <PrivacyTipIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {formErrors?.phone && (
        <Typography sx={styles.errorText}>Please enter a valid phone number</Typography>
      )}

      {/* Popover with OTP information */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        elevation={1}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: styles.popover.backgroundColor,
          },
        }}
      >
        <Typography sx={{ p: 2, color: styles.popover.color }}>
          We use One-Time Password (OTP) via text message to verify your phone number during registration.
        </Typography>
      </Popover>

      <FormControlLabel
        control={
          <Checkbox
            checked={userData.terms}
            onChange={() => setUserData({ ...userData, terms: !userData.terms })}
          />
        }
        label="I agree to terms and conditions"
        sx={styles.checkbox}
      />
      {formErrors?.terms && (
        <Typography sx={styles.errorText}>Please agree to terms and conditions</Typography>
      )}

      <Box sx={styles.buttonContainer}>
        <Button fill="solid" text="Register" onClick={handleSubmit} disabled={loading} />
      </Box>
    </Box>
  );
};

export default RegisterForm;
