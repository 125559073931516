export const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomMultipleOf5 = (min, max) => {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return Math.floor(randomNumber / 5) * 5;
};

export const getRandomMultipleOf500 = (min, max) => {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return Math.floor(randomNumber / 500) * 500;
};

// Generate a random rating between 4.5 and 5.0
export const randomRating = Math.random() * (5.0 - 4.5) + 4.5;
// Generate a random number of ratings between 7 and 28
export const randomNumRatings = Math.floor(Math.random() * (28 - 7 + 1)) + 7;
