const config = {
  //apiUrl: process.env.REACT_APP_API_URL,
  apiUrl: (() => {
    const appEnv = process.env.REACT_APP_ENV || process.env.NODE_ENV;
    console.log("App Environment:", appEnv);
    switch (appEnv) {
      case "development":
        return process.env.REACT_APP_DEV_API_URL;
      case "staging":
        return process.env.REACT_APP_STAGING_API_URL;
      case "production":
        return process.env.REACT_APP_API_URL;
      default:
        return process.env.REACT_APP_API_URL; // Default to production URL
    }
  })(),
  googleAPI: process.env.REACT_APP_GOOGLE_API_KEY,
  mapBoxToken: process.env.MAPBOX_PUBLIC_TOKEN
};

export default config;