import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Styles
import { colors, spacingStyles } from "../../../assets/styles/global.js";
import "../../../assets/styles/global.css";

// Components
import GymDetailsHeader from "../../../Components/Gym/GymDetailsHeader.js";
import RenderIcons from "../../../Components/Common/RenderIcons.js";
import { CustIcons } from "../../../Services/Constants/customIcons.js";
import { Checkout } from "../../Public/Checkout.js";
import SubscriptionCheckout from "../../Public/SubscriptionCheckout.js";

// API
import { getMockData } from "../../../Services/APIHandlers/BookingApi.js";
import { Button } from "../../../Components/Common/Buttons/Button.js";
import StarIcon from "@mui/icons-material/Star";

import SlideInPanel from "../../../Components/Modals/slideInPanel.js";
import DetailsSchedule from "../../../Components/Calendar/DetailsSchedule.js";

import AnimatedBackground from "../../../Components/Backgrounds/SlidingDiagonals";

import TrainerList from "../../../Components/List/TrainerList";
import { useTheme } from '@mui/material/styles';
import { Box, Tabs as MuiTabs, Tab } from "@mui/material";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import OptionsForm from "../../../Components/Forms/DetailsOptionsForm.js";
import { unset } from "lodash";

const GymDetails = ({ Data }) => {

  // console.log(Data); 
  //const dispatch = useDispatch();

  //const Gym = useSelector((state) => state.selectedGym);
  const [gallery, setGallery] = useState([]);
  const [items] = useState({});

  const [isExpanded, setIsExpanded] = useState(false);

  const [scheduleData, setSchedule] = useState({});
  const daySelect = useSelector((state) => state.booking.selectedDate);
  const timeSelect = useSelector((state) => state.booking.selectedTime);

  const caretSymbol = isExpanded ? "▲" : "▼";
  const scheduleRef = useRef(null);

  const [isPanelOpen, setPanelOpen] = useState(false);
  const [checkoutType, setCheckoutType] = useState(null); // "one-time" or "subscription"
  const [checkoutData, setCheckoutData] = useState(null); // Stores booking or subscription details

  const [checkoutMode, setCheckoutMode] = useState("light");

  const [optionSelect, setOptionSelect] = useState(null);
  const [paymentFrequency, setPaymentFrequency] = useState(null);

  const theme = useTheme();
  const navigate = useNavigate();

  const openPanel = (type, data) => {
    setCheckoutType(type); // "one-time" or "subscription"
    setCheckoutData(data); // Booking details or subscription details

    setPanelOpen(true);
  };

  const closePanel = () => {
    setPanelOpen(false);
  };

  //console.log(Data); 

  const getSchedule = () => {
    //console.log(Data);

    if (Data !== null) {
      // const response = await getSiteData(Data.slug); ///getMockData();
      // const gallery = await Data.gallery;

      setSchedule(Data.calendar.schedule);
      setGallery(Data.gallery);

      //console.log(gallery); 

    } else {
      const response = getMockData();
      const gallery = response.data.publicData.gallery;

      console.log(gallery);

      setSchedule(response.data.publicData.calendar.schedule);
      setGallery(gallery);
    }
  };

  useEffect(() => {
    getSchedule();

  }, []);

  // useEffect(() => {
  //   //console.log(scheduleRef)
  //   if (scheduleData && daySelect !== null && scheduleRef !== null) {
  //     scheduleRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [daySelect]);

  const [infoTab, setInfoTab] = React.useState('1');

  const handleInfoTabChange = (event, newValue) => {
    //console.log(newValue);
    setInfoTab(newValue);
  };

  const hasValidItems = (dataObj) => {
    return Object.values(dataObj).some(value => value);
  };

  return (
    <Box
      sx={{
        padding: "10px 10px 0px",
        [theme.breakpoints.up('sm')]: {
          paddingTop: "20px", // Apply this padding-top when screen width is 640px or more
        },
      }}
    >
      <div>
        <AnimatedBackground />
      </div>
      <div style={{}}>
        <div className="gymtop">
          <div className="gymdetails" style={{ ...spacingStyles.pb60 }}>
            {/* Gym Header */}
            {/* Top Level Details */}
            <div>
              <GymDetailsHeader Data={Data} showProfilePic={true} />

              {/* Gallery */}
              <div
                style={{
                  position: "relative",
                  ...spacingStyles.flexRow,
                  ...spacingStyles.mt10,
                  ...spacingStyles.mb10,
                  ...spacingStyles.w100,
                }}
              >
                {gallery &&
                  gallery.length > 3 &&
                  [...Array(4).keys()].map((i) => (
                    <img
                      key={i}
                      src={gallery[i].url}
                      className="prev-gallery"
                      alt=""
                      style={{
                        ...spacingStyles.mh2,
                        ...spacingStyles.br10,
                      }}
                    />
                  ))}
              </div>


            </div>
            {/* Options Form */}
            <div className="mobileoptionsForm">
              <OptionsForm
                scheduleData={scheduleData}
                Data={Data}
                daySelect={daySelect}
                timeSelect={timeSelect}
                openPanel={openPanel}
                spacingStyles={spacingStyles}
                setOptionSelect={setOptionSelect} 
                setPaymentFrequency =  {setPaymentFrequency}
              />
            </div>

            {/* Additional Info */}
            <div className="additional-info-container">

              {/* About */}
              {Data?.profile.descr !== "" && (
                <div
                  className="flexCol leftView"
                  style={{
                    ...spacingStyles.w100,
                    ...spacingStyles.bbg,
                  }}
                >
                  <h3 className="cust_header left" style={{ ...spacingStyles.w100 }} >About this HopperSite:</h3>

                  <p className="left" style={{ ...spacingStyles.mb0, color: theme.palette.text.secondary }}>
                    {isExpanded
                      ? Data?.profile.descr
                      : Data?.profile.descr.slice(0, 80)}
                  </p>

                  {Data?.profile.descr && Data.profile.descr.length > 80 ? (
                    <div
                      style={{ ...spacingStyles.mb10 }}
                      onClick={() => {
                        setIsExpanded(!isExpanded);
                      }}
                    >
                      <h5 style={{ ...spacingStyles.mt5, color: theme.palette.text.primary }}>
                        {isExpanded
                          ? "See Less " + caretSymbol
                          : "See More " + caretSymbol}
                      </h5>
                    </div>
                  ) : null}
                </div>
              )}

              {/* Amenities */}
              {hasValidItems(Data.details.amenities) && (
                <div style={{ paddingTop: '20px' }}>
                  <div style={{}}>
                    <h3 className="cust_header left">Amenities:</h3>
                  </div>
                  <div
                    className="flexWrap flexRow"
                    style={{}}
                  >
                    {items &&
                      Object.entries(Data.details.amenities).map(([item, value], a) => {
                        if (value && CustIcons[item]?.displayName !== "") {
                          return (
                            <div
                              key={`amenity-${item}-${a}`}
                              className="flexRow chp-border"
                              style={{
                                ...spacingStyles.p5,
                                ...spacingStyles.mr5,
                              }}
                            >
                              <RenderIcons
                                iconName={item}
                                size={20}
                                color={theme.palette.text.primary}
                              />
                              <span style={{ color: theme.palette.text.primary, ...spacingStyles.pl5 }}>
                                {CustIcons[item]?.displayName}
                              </span>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
              )}

              {/* Facilities */}
              {hasValidItems(Data.details.extras) && (
                <div>
                  <div style={{}}>
                    <h3 className="cust_header left">Facilities:</h3>
                  </div>
                  <div
                    className="flexWrap flexRow"
                    style={{}}
                  >
                    {items &&
                      Object.entries(Data.details.extras).map(([item, value], a) => {
                        if (value) {
                          return (
                            <div
                              key={a}
                              className="flexRow chp-border"
                              style={{
                                ...spacingStyles.p5,
                                ...spacingStyles.mr5,
                              }}
                            >
                              <RenderIcons
                                iconName={item}
                                width={25} height={25}
                                color={theme.palette.text.primary}
                              />
                              <span
                                style={{
                                  color: theme.palette.text.primary,
                                  ...spacingStyles.p5,
                                  ...spacingStyles.ml5,
                                }}
                              >
                                {CustIcons[item].displayName}
                              </span>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
              )}

              {/* Equipment */}
              {hasValidItems(Data.details.equipment) && (
                <div>
                  <div style={{}}>
                    <h3 className="cust_header left">Equipment:</h3>
                  </div>
                  <div
                    className="flexWrap flexRow"
                    style={{}}
                  >
                    {items &&
                      Object.entries(Data.details.equipment).map(([item, value], a) => {
                        if (value) {
                          return (
                            <div
                              key={a}
                              className="flexRow chp-border"
                              style={{
                                ...spacingStyles.p5,
                                ...spacingStyles.mr5,
                              }}
                            >
                              <RenderIcons
                                iconName={item}
                                size={25}
                                color={theme.palette.text.primary}
                              />
                              <span
                                style={{
                                  color: theme.palette.text.primary,
                                  ...spacingStyles.p5,
                                  ...spacingStyles.ml5,
                                }}
                              >
                                {CustIcons[item].displayName}
                              </span>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
              )}

              {/* What is a HopperSite? */}
              <div
                className="flexCol leftView"
                style={{
                  ...spacingStyles.w100,
                  ...spacingStyles.bbg,
                }}
              >
                <h3 className="cust_header left" style={{ ...spacingStyles.w100 }} >What is a HopperSite?</h3>
                <p className="left" style={{ ...spacingStyles.mb0, color: theme.palette.text.secondary }}>
                  HopperSites are premier gym spaces partnered with HopperFit, providing professional environments for Hoppers—our community of certified personal trainers and physical therapists—to train their clients. As a HopperSite, this gym offers flexible, high-quality facilities designed to support personalized fitness and wellness journeys.
                </p>
              </div>

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={infoTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <MuiTabs value={infoTab} onChange={handleInfoTabChange} aria-label="Become A Hopper Tabs">
                      <Tab label="How to Hop Here?" value="1" sx={{ fontSize: "16px", fontWeight: "bold", textTransform: "capitalize" }} />
                      <Tab label="Partner with us?" value="2" sx={{ fontSize: "16px", fontWeight: "bold", textTransform: "capitalize" }} />
                      {/* <Tab label="Item Three" value="3"  /> */}
                    </MuiTabs>
                  </Box>
                  <TabPanel className="TabPanel" value="1" index={0} style={{ textAlign: 'left', color: theme.palette.text.secondary, paddingBottom: 'unset' }}>
                    <h4 style={{ color: theme.palette.text.primary }} >Get Started in 3 Easy Steps</h4>
                    <ul className="details-cta-list">
                      <li><span style={{ color: theme.palette.text.primary, fontWeight: 600 }}>Sign Up or Log In:</span> Create your HopperFit account to access our network of premium HopperSites.</li>
                      <li><span style={{ color: theme.palette.text.primary, fontWeight: 600 }} >Browse and Book:</span> Explore available gym spaces and book those that meet your training and client needs.</li>
                      <li><span style={{ color: theme.palette.text.primary, fontWeight: 600 }} >Start Training:</span> Use the space to deliver exceptional sessions and grow your business.</li>
                    </ul>
                  </TabPanel>
                  <TabPanel value="2" index={1} style={{ textAlign: 'left', color: theme.palette.text.secondary, paddingBottom: 'unset' }}>
                    <h4 style={{ color: theme.palette.text.primary }} >Why Train with HopperFit?</h4>
                    <ul className="details-cta-list">
                      <li><span style={{ color: theme.palette.text.primary, fontWeight: 600 }} >Book Gym Space:</span> Easily find and reserve gym spaces tailored to your preferences and your clients’ needs.
                        Flexible Scheduling: Choose spaces that align with your availability, offering greater flexibility and reach.
                        Boost Your Reputation and Income
                      </li>
                      <li><span style={{ color: theme.palette.text.primary, fontWeight: 600 }} >Professional Environment:</span> Impress clients by hosting sessions in well-equipped, high-quality gyms.
                        Transparent Pricing: Manage finances confidently with upfront costs and secure payment processing.
                        Join a Network of Quality Gyms
                      </li>
                      <li><span style={{ color: theme.palette.text.primary, fontWeight: 600 }} >Discover New Opportunities:</span> Connect with reputable gyms seeking skilled trainers like you.

                      </li>
                    </ul>
                  </TabPanel>
                  {/* <TabPanel value="3" index={2}>
                    Item Three
                  </TabPanel> */}
                </TabContext>
              </Box>

            </div>

            {/* What is a CTA */}
            <div
              className="flexCol leftView flexCenter"
              style={{
                ...spacingStyles.w100,
                display: "flex",

              }}
            >
              <h4 style={{ color: theme.palette.text.primary }} className="details-cta">Ready to take your training to the next level?</h4>
              <p style={{ color: theme.palette.text.secondary }} >Start today and transform your business!</p>
              <Box className="details-cta-btn-container" sx={{ margin: "20px 0", maxWidth: '300px' }}>
                <Button text="Become A Hopper" fill="outline" onClick={() => navigate("/register?type=Trainer")} />
              </Box>
            </div>
          </div>
          <div className="deskoptionsForm">
            <OptionsForm
              scheduleData={scheduleData}
              Data={Data}
              daySelect={daySelect}
              timeSelect={timeSelect}
              openPanel={openPanel}
              spacingStyles={spacingStyles}
              setOptionSelect={setOptionSelect} 
              setPaymentFrequency =  {setPaymentFrequency}
            />
          </div>
        </div>
        {daySelect !== null && timeSelect !== null && (
          <SlideInPanel
            isOpen={isPanelOpen}
            closePanel={closePanel}
            mode={checkoutMode}
          >
            <Checkout isOpen={isPanelOpen} mode={checkoutMode} />
          </SlideInPanel>
        )}

        {/* SlideInPanel to Handle Both Checkouts */}
        {checkoutData && (
          <SlideInPanel isOpen={isPanelOpen} closePanel={closePanel} mode={checkoutMode}>
            {checkoutType === "one-time" ? (
              <Checkout isOpen={isPanelOpen} mode={checkoutMode} bookingData={checkoutData} />
            ) : (
              <SubscriptionCheckout isOpen={isPanelOpen} mode={checkoutMode} subscriptionData={checkoutData} subscriptionOption = {optionSelect} paymentFrequency = {paymentFrequency} />
            )}
          </SlideInPanel>
        )}


      </div>
    </Box>
  );
};

export default GymDetails;
