import { Stack, Typography, useTheme } from "@mui/material";
import { globalStyles, colors } from "../../../assets/styles/global";
import RenderIcons from "../RenderIcons";

export default function OnboardingButton({
  selected,
  text,
  onClick,
  showCusIcon,
  cusIconName = "treadmill",
  iconWidth = 25,
  iconHeight = 25,
  subtext = null,
}) {
  const theme = useTheme();

  const buttonStyle = {
    padding: 0,
    minHeight: 35,
    backgroundColor: "rgba(0, 0, 0, 0)",
    ...globalStyles.flexRow,
    ...(showCusIcon ? globalStyles.flexStart : globalStyles.flexCenter),
    alignItems: 'center',
    cursor: "pointer",
    overflow: "hidden",
  };

  const sharedTypographyStyles = {
    ...globalStyles.h2,
    ...globalStyles.mr7p,
    ...globalStyles.ml7p,
    textTransform: "capitalize",
    lineHeight: 1.1,
    fontWeight: 600,
  };

  const selectedStyles = {
    borderWidth: 2,
    ...globalStyles.radius,
    ...buttonStyle,
    backgroundColor: theme.palette.mode === 'dark' ? colors.wh_18pct.color : colors.bl_44pct.color,
  };

  const unselectedStyles = {
    ...buttonStyle,
    borderColor: theme.palette.mode === 'dark' ? '#444' : '#ccc',
  };

  return (
    <>
      {selected ? (
        <div onClick={() => onClick()} style={selectedStyles}>
          {showCusIcon && (
            <RenderIcons
              iconName={cusIconName}
              width={iconWidth}
              height={iconHeight}
              // Set icon color to white when in light mode, and primary color in dark mode
              color={theme.palette.mode === 'light' ? colors.green.color : colors.green.color}
            />
          )}
          <Stack sx={{width: '100%'}} direction="column" >
            <Typography
              sx={{
                ...sharedTypographyStyles,
                color: theme.palette.mode === 'dark' ? colors.white.color : colors.white.color,
                fontSize: '14px',
              }}
            >
              {text}
            </Typography>
            {/* {subtext &&
              <Typography
                sx={{
                  ...sharedTypographyStyles,
                  color: theme.palette.secondary.primary,
                  fontSize: '12px',
                }}
              >
                {subtext}
              </Typography>} */}
          </Stack>
        </div>
      ) : (
        <div onClick={() => onClick()} style={unselectedStyles}>
          {showCusIcon && (
            <RenderIcons
              iconName={cusIconName}
              width={iconWidth}
              height={iconHeight}
              // Set icon color to white when in light mode, and secondary color in dark mode
              color={theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.main}
            />
          )}
          <Typography
            sx={{
              ...sharedTypographyStyles,
              color: theme.palette.secondary.contrastText,
              fontSize: '14px',
            }}
          >
            {text}
          </Typography>
        </div>
      )}
    </>
  );
}
