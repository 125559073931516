import React, { useMemo, useEffect, useContext, useState } from "react";
import AuthContext from "../../Navigation/AuthWrapper";

import { useSelector, useDispatch } from "react-redux";
import { addUser } from "../../Services/Redux/Slices/User.Slice";
import { addWalletDetails } from "../../Services/Redux/Slices/Wallet.Slice";
import { Box, Typography, Grid, Tabs as MuiTabs, Tab } from "@mui/material";
import WalletDetails from "../../Components/Wallet/WalletDetails";
import Transfers from "../../Components/Wallet/Transfers";
import { getWalletData, setupTransfer, cashOut, updateAccessToken, createPlaidLinkToken } from "../../Services/APIHandlers/WalletApi";

import { globalStyles, spacingStyles } from "../../assets/styles/global";
import { useTheme } from "@mui/material/styles";
import { Button } from "../../Components/Common/Buttons/Button"; // Your custom Button
import { LoadingButton } from "@mui/lab"; // Importing LoadingButton
import CircularProgress from "@mui/material/CircularProgress";

import { PlaidLink } from "react-plaid-link";
import Link from "../../Components/Link";
import { TbChevronDownLeft } from "react-icons/tb";

const StyledPlaidLinkButton = ({ fill, text, linkToken, onSuccess }) => {
  const theme = useTheme();
  const buttonStyle = {
    background: theme.palette.gradient.confirm,
    color: theme.palette.common.white,
    padding: '15px 20px',
    borderRadius: '50px',
    border: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '20px',
    width: "100%",
    fontWeight: "bold",
    fontSize: 18,
    textTransform: 'uppercase',
  };

  const dynamicButtonStyles = {
    default: {
      ...buttonStyle,
      background: theme.palette.gradient.confirm,
      color: theme.palette.common.white,
    },
    outline: {
      ...buttonStyle,
      background: "unset",
      border: `1px solid ${theme.palette.grey[500]}`,
      color: theme.palette.text.primary, // Adjust text color for outline mode
    },
  }

  let appliedStyle = dynamicButtonStyles.default;
  if (fill === "outline") {
    appliedStyle = dynamicButtonStyles.outline;
  }

  if (!linkToken) return null; // Return null if no linkToken is available

  return (
    <PlaidLink token={linkToken} onSuccess={onSuccess}
      style={{
        ...appliedStyle
      }}
    >
      {text}
    </PlaidLink >
  );
};

const Wallet = () => {
  const token = useSelector((state) => state.auth.token);
  const [isUpdatingBanking, setIsUpdatingBanking] = useState(false); // Loading state
  const details = useSelector((state) => state.wallet);
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const theme = useTheme();

  // State to store the link token response from the API
  const [linkToken, setLinkToken] = useState(null);


  const getUserData = async () => {
    try {
      const storedUserData = await localStorage.getItem("user");
      setUserData(JSON.parse(storedUserData));
    } catch (error) {
      console.error("Error retrieving user data:", error);
    }
  };

  useEffect(() => {

    getUserData();
    const fetchData = async () => {
      setIsUpdatingBanking(true); // Start loading when fetching data
      try {
        const response = await getWalletData(auth.token);
        if (response.success) {
          //console.log("Wallet Data:", response.data);
          dispatch(addWalletDetails(response.data));
        } else {
          console.error("Error fetching wallet data:", response.message);
        }
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      } finally {
        setIsUpdatingBanking(false); // Stop loading when done
      }
    };

    const fetchLinkToken = async () => {
      try {
        const response = await createPlaidLinkToken(
          token
        );

        setLinkToken(response.data.link_token); // Store the link token in state
        localStorage.setItem("link_token", response.data.link_token);

      } catch (err) {
        console.error(err);
      }
    };

    //fetchLinkToken();
    //console.log(auth.token);


    fetchData();
  }, [auth.token]);



  const handleSetupTransferClick = async () => {
    setIsUpdatingBanking(true); // Set loading state
    try {
      const response = await setupTransfer(auth.token);
      if (response.data.user) {
        dispatch(addUser(response.data.user));
      }
      if (response.data.connect_url) {
        window.location.href = response.data.connect_url;
      }
    } catch (err) {
      console.error(err);
    } finally {
      // No need to set loading state here; handled in data fetching
    }
  };

  const handleCashOut = async () => {
    try {
      const response = await cashOut(auth.token);
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getPublicToken = async (public_token) => {
    const handleSubmit = async () => {
      try {
        const response = await updateAccessToken(
          public_token,
          token
        );
        if (response.data.success) {
          console.log(response.data);
        } else {
          console.log(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    console.log("Access Token: ", public_token);
    handleSubmit();
  };

  const [activeTab, setActiveTab] = useState("Balance");
  const availableTabs = ["Balance", "Transfers"];
  const handleTabChange = (event, newValue) => setActiveTab(newValue);
  const Tabs = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <MuiTabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
        >
          {availableTabs.map((tabName) => (
            <Tab key={tabName} label={tabName} value={tabName} />
          ))}
        </MuiTabs>
      </Box>
    );
  };

  return (
    <Box sx={{ ...spacingStyles.desktopMaxWidth, padding: "0 5px" }}>
      <Typography sx={{ maxWidth: "100vw", padding: "20px 0 40px" }} variant="h4" color={theme.palette.text.primary}>
        Earnings
      </Typography>

      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={6} >
          <Box sx={{ display: 'grid', gridTemplateColumns: "1fr 1fr" }}>
            <Box sx={{ ...globalStyles.flexCol, ...globalStyles.flexCenter, ...globalStyles.alignCenter, borderRight: `1px solid ${theme.palette.background.opposite}` }} mb={2}>
              <Typography variant="h6" color="text.secondary" lineHeight={1.2} gutterBottom padding="0px 20px" >
                Pending Balance
              </Typography>

              <Box display="flex" alignItems="center">
                <Typography variant="h2" color="success.main">
                  $
                </Typography>
                <Typography variant="h2" color="success.main" ml={1}>
                  {details?.current_stats?.cash_pending || 0}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="text.secondary">
                USD
              </Typography>
            </Box>
            <Box sx={{ ...globalStyles.flexCol, ...globalStyles.flexCenter, ...globalStyles.alignCenter }} mb={2}>
              <Typography variant="h6" color="text.secondary" lineHeight={1.2} gutterBottom padding="0px 20px">
                Cashout Available
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="h2" color="success.main">
                  $
                </Typography>
                <Typography variant="h2" color="success.main" ml={1}>
                  {details?.current_stats?.cash_cashout || 0}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="text.secondary">
                USD
              </Typography>
            </Box>
          </Box>
          {/* Buttons */}
          <Box>
            {/* Transfer To Bank Button */}
            <Button
              disabled={
                userData?.stripe_payoutId === null
                || details?.current_stats?.cash_cashout === 0
              }
              fill={userData?.stripe_payoutId && details?.current_stats?.cash_cashout > 0 ? "confirm" : "outline"}
              text="Cashout To Bank"
              onClick={handleCashOut}
              sx={{ marginBottom: '20px' }}
            />

            {/* Setup Transfer Button 
            {userData?.stripe_payoutId === null && (
              <StyledPlaidLinkButton text="Connect your bank account" linkToken={linkToken} onSuccess={getPublicToken} />
            )}
            {userData?.stripe_payoutId !== null && (
              <StyledPlaidLinkButton fill="outline" text="Switch Bank Account" linkToken={linkToken} onSuccess={getPublicToken} />
            )}
            */}

            <Button
              disabled={true}
              fill={userData?.stripe_payoutId && details?.current_stats?.cash_cashout > 0 ? "confirm" : "outline"}
              text="Connect Your Bank"
              sx={{ marginBottom: '20px' }}
            />
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item style={{ paddingTop: 'unset' }} xs={12} md={6} >
          {/* Tabs */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <MuiTabs
              value={activeTab}
              onChange={handleTabChange}
              centered
              textColor="primary"
              indicatorColor="primary"
            >
              {availableTabs.map((tabName) => (
                <Tab key={tabName} label={tabName} value={tabName} />
              ))}
            </MuiTabs>
          </Box>
          {/* Details */}
          <Box sx={{ mt: 3 }}>
            {activeTab === "Balance" ? (
              <WalletDetails details={details} />
            ) : (
              <Transfers />
            )}
          </Box>
        </Grid>
      </Grid>
      {/* {linkToken && <p>Link Token: {linkToken}</p>} */}
    </Box>
  );
};

export default Wallet;
