import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Navigation/AuthWrapper"; // Adjust path as needed
import RegisterForm from "../../Components/Forms/RegisterForm";
import OTPForm from "./OTPForm";
import { Button, Box, Typography, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
//import { addSubscriptionRequest } from "../../Services/APIHandlers/ProfileApi";
import LoginForm from "../../Components/Forms/LoginForm";

const SubscriptionPlanForm = ({ slug, monthlyRate = 0, openPanel, setPaymentFrequency }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const formMode = theme.palette.mode;

  const [planOption, setPlanOption] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLogged, setIsLogged] = useState(false);
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isOTPConfirmed, setIsOTPConfirmed] = useState(false);

  const [authToggle, setAuthToggle] = useState("Login");
  const auth = useContext(AuthContext);

  const handleAuthToggle = (value) => {
    setAuthToggle(value);
};

  useEffect(() => {
    setShowAuthForm(false);
  }, []);

  useEffect(() => {
    setIsLogged(auth.token !== "");
  }, [auth.token]);

  // Subscription plan options
  const planOptions = [
    { label: "Monthly Plan", value: "monthly", multiplier: 1 },
    { label: "Bi-Weekly Plan", value: "bi-weekly", multiplier: 0.5 },
    { label: "Weekly Plan", value: "weekly", multiplier: 0.25 },
  ];

  // Update total price when plan changes
  useEffect(() => {
    if (planOption) {
      const selectedPlan = planOptions.find((p) => p.value === planOption);
      setTotalPrice((monthlyRate * selectedPlan.multiplier).toFixed(2));

      setPaymentFrequency(selectedPlan.value);

    }
  }, [planOption, monthlyRate]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!isLogged) {
      setShowAuthForm(true);
    } else {
      proceedToCheckout();//saveRequest();
    }
  };

  const handleRegistrationSuccess = () => {
    setIsRegistered(true);
  };

  const handleOTPSuccess = () => {
    setIsOTPConfirmed(true);
    setIsLogged(true);
    proceedToCheckout();//saveRequest();
  };


  const proceedToCheckout = () => {
    const data = {
      plan: planOption,
      price: totalPrice,
      slug: slug,
    };
    openPanel("subscription", data); // Opens `SlideInPanel` with SubscriptionCheckout
  };


  const saveRequest = async () => {
    const data = {
      plan: planOption,
      price: totalPrice,
      slug: slug,
    };

    // Navigate to the TermsAgreement screen before finalizing the subscription
    navigate("/terms-agreement", { state: data });

    // try {
    //   const response = await addSubscriptionRequest(data, auth.token);
    //   if (response) {
    //     navigate("/confirm", { state: { confirmationType: "subscription" } });
    //   } else {
    //     console.error("Error processing subscription");
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
  };

  return (
    <Box sx={{ p: 4 }}>
      {!showAuthForm && (
        <form onSubmit={handleFormSubmit}>
          <Typography variant="h6" sx={{ mb: 2, color: theme.palette.text.primary }}>
            Step 1: Choose a Payment Plan
          </Typography>
          <RadioGroup value={planOption} onChange={(e) => setPlanOption(e.target.value)}>
            {planOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={`${option.label} - $${((monthlyRate * option.multiplier) / 100).toFixed(2)}`}
                sx={{ color: theme.palette.text.primary }}
              />
            ))}
          </RadioGroup>
          <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold", color: theme.palette.text.primary, textTransform: "capitalize" }}>
            {planOptions.find((p) => p.value === planOption)?.value} Payment: ${(totalPrice / 100).toFixed(2)}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!planOption}
            fullWidth
            sx={{ mt: 3 }}
          >
            Continue
          </Button>
        </form>
      )}
      {showAuthForm && !isRegistered && !isLogged && (
        <>
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Reserve now.
            </Typography>
            <Button
              variant={authToggle === "Register" ? "contained" : "outlined"}
              onClick={() => handleAuthToggle("Register")}
              sx={{ mr: 1 }}
            >
              Register
            </Button>
            <Button
              variant={authToggle === "Login" ? "contained" : "outlined"}
              onClick={() => handleAuthToggle("Login")}
            >
              Login
            </Button>

            <Box sx={{ mt: 2 }}>
              {authToggle === "Login" && <LoginForm onSuccess={handleRegistrationSuccess} mode={formMode} />}
              {authToggle === "Register" && <RegisterForm onSuccess={handleRegistrationSuccess} mode={formMode} />}
            </Box>
          </Box>
        </>
      )}
      {isRegistered && !isLogged && (
        <Box>
          <OTPForm onSuccess={handleOTPSuccess} />
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionPlanForm;
