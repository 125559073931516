import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { spacingStyles } from "../../../assets/styles/global.js";
import "../../../assets/styles/global.css";

import DashWelcome from "../../../Components/Dashboard/DashWelcome.js";
import DashUpcoming from "../../../Components/Dashboard/DashUpcoming.js";
import DashWallet from "../../../Components/Dashboard/DashWallet.js";

import SlideInPanel from "../../../Components/Modals/slideInPanel.js";
import ImageUploader from "../../../Components/Common/ImageUpload.js";

import { updateProfilePic } from "../../../Services/APIHandlers/ProfileApi.js";

const GymDash = ({ Data }) => {
  const token = useSelector((state) => state.auth.token);
  // const allData = useSelector((state) => state);

  const [isPanelOpen, setPanelOpen] = useState(false);

  const openPanel = () => {
    setPanelOpen(true);
  };

  const closePanel = () => {
    setPanelOpen(false);
  };

  const onLoadProfilePicSuccess = (picture_id) => {
    const response = updateProfilePic(picture_id, token);
    console.log(response);
    window.location.reload();
  };

  useEffect(() => {
    localStorage.setItem("userTypeSet", true);
  }, []);

  return (
    <div
      style={{
        ...spacingStyles.desktopMaxWidth,
      }}
    >
      <div       style={{
        padding: '20px',
      }}>
        <DashWelcome openImageUploader={openPanel}></DashWelcome>
        <div className="dash-contain">
          <h2 className="orange left">Upcoming Bookings</h2>
          <h3 className="left" style={{ ...spacingStyles.mb0 }}>
            This Week
          </h3>
        </div>
        <DashUpcoming></DashUpcoming>
        <div className="dash-contain">
          <h2 className="orange left">Wallet</h2>
        </div>
        <DashWallet></DashWallet>
        <SlideInPanel
          isOpen={isPanelOpen}
          closePanel={closePanel}
          content={ImageUploader}
          children={<ImageUploader onSuccess={onLoadProfilePicSuccess} />}
        />
      </div>
    </div>
  );
};

export default GymDash;
