import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateBusiness } from "../../APIHandlers/ProfileApi";


// Async thunk for updating business information
export const updateBusinessAsync = createAsyncThunk(
  "Business/updateBusiness",
  async ({ updatedBusinessData, token }, { dispatch, getState, rejectWithValue }) => {
    //console.log(updatedBusinessData);
    try {
      const response = await updateBusiness( updatedBusinessData, token);
      //console.log(response);
      // dispatch(showSuccessMessage("Business updated successfully"));
      return response.data;
    } catch (error) {
      console.log(error);
      // dispatch(showErrorMessage("Failed to update business"));
      return rejectWithValue(error.response.data);
    }
  }
);