import React, { useEffect, useState, useContext } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { nav } from "../Services/Constants/navigation";
import GuestRoute from "./GuestRoute";
import PrivateRoute from "./PrivateRoute";
import AuthContext from "./AuthWrapper";
import { DeskSideMenu } from "./Menus";
import AnimatedBackground from "../Components/Backgrounds/SlidingDiagonals";
import { MobileMenu } from "./Menus";


export const RenderRoutes = () => {
  const [activeScreen, setActiveScreen] = useState('Setup');
  const { role_id } = useContext(AuthContext);

  const auth = useContext(AuthContext);
  const isAuthenticated = auth.token !== "";

  const navigation = useNavigate();
  const location = useLocation();

  const handleMenuClick = (menuItem) => {

    //console.log(menuItem);
    const onboardingPrefix = role_id === 3 ? "/TrainerOnboarding" : "/SiteOnboarding";

    //setActiveScreen(menuItem);
    // Logic to navigate based on the menu item clicked
    switch (menuItem) {
      case "List Your Space":
        navigation("/SiteOnboarding");
        break;
      case "Trainer Setup":
        navigation("/TrainerOnboarding");
        break;
      case "Setup":
        navigation(`${onboardingPrefix}#intro`);
        break;
      case "Description":
        navigation(`${onboardingPrefix}#description`);
        break;
      case "Gallery":
        navigation(`${onboardingPrefix}#gallery`);
        break;
      case "Space":
        navigation(`${onboardingPrefix}#space`);
        break;
      case "Amenities":
        navigation(`${onboardingPrefix}#amenities`);
        break;
      case "Location":
        navigation(`${onboardingPrefix}#location`);
        break;
      case "Schedule":
        navigation(`${onboardingPrefix}#schedule`);
        break;
      case "Special":
        navigation(`${onboardingPrefix}#special`);
        break;
      case "SetupComplete":
        navigation(`${onboardingPrefix}#setupcomplete`);
        break;

      // Trainer-specific items
      case "Certification":
        navigation(`${onboardingPrefix}#certification`);
        break;
      case "Insurance":
        navigation(`${onboardingPrefix}#insurance`);
        break;
      case "DemographicInfo":
        navigation(`${onboardingPrefix}#demographicinfo`);
        break;
      case "Coach":
        navigation(`${onboardingPrefix}#coach`);
        break;
      case "Goal":
        navigation(`${onboardingPrefix}#goal`);
        break;
      case "Area":
        navigation(`${onboardingPrefix}#area`);
        break;
      case "Motivate":
        navigation(`${onboardingPrefix}#motivate`);
        break;
      case "ProfilePic":
        navigation(`${onboardingPrefix}#profilepic`);
        break;
      case "Success":
        navigation(`${onboardingPrefix}#success`);
        break;

      // Common items
      case "Earnings":
        navigation("/Wallet");
        break;
      case "Home":
        navigation("/");
        break;
      case "settings":
        navigation("/Profile");
        break;
      case "Settings":
        navigation("/Profile");
        break;
      case "UserType":
        navigation("/UserType");
        break;

      default:
        navigation(`/${menuItem.toLowerCase()}`); // Fallback to default screen
    }
  };

  useEffect(() => {
    const loginOrRegisterRoutes = ["/","/Login", "/Register"];
    const dashRoutes = ["/Dashboard", "/TrainerDash", "/GymDash"];
    const onboardingRoutes = ["/SiteOnboarding", "/TrainerOnboarding"];

    if ((role_id === "0" || role_id === 0) && loginOrRegisterRoutes.includes(location.pathname) && isAuthenticated) {
      navigation("/UserType");
    } else if (loginOrRegisterRoutes.includes(location.pathname) && isAuthenticated) {
      navigation("/Dashboard");
    } else if (dashRoutes.includes(location.pathname) && (role_id === "0" || role_id === 0)) {
      navigation("/UserType");
    } else if (onboardingRoutes.includes(location.pathname) && (role_id === "0" || role_id === 0)) {
      navigation("/UserType");
    }
  }, [role_id, location.pathname, navigation]);

  const filteredNav = nav.filter((route) => {

    //console.log(route.path); 
    if (route.path === "/SiteOnboarding" && role_id === "3") {
      //console.log(route.path,true); 
      return false;
    }
    if (route.path === "/TrainerOnboarding" && role_id === "2") {
      return false;
    }

    return true;
  });

  return (
    <div className={`content-container`}>
      <DeskSideMenu onMenuClick={handleMenuClick} activeScreen={activeScreen} />
      <div className="main-content-container">
        <AnimatedBackground />
        <Routes>
          {filteredNav.map((route, i) => {
            if (route.isGuestAllowed) {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={<GuestRoute>{React.cloneElement(route.element, { activeScreen, setActiveScreen })}</GuestRoute>}
                />
              );
            } else if (route.isPrivate) {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={<PrivateRoute>{React.cloneElement(route.element, { activeScreen, setActiveScreen })}</PrivateRoute>}
                />
              );
            } else if (!route.isPrivate) {
              return <Route key={i} path={route.path} element={React.cloneElement(route.element, { activeScreen, setActiveScreen })} />;
            } else return false;
          })}
        </Routes>
      </div>

      <MobileMenu activeScreen={activeScreen} onMenuClick={handleMenuClick}   />
    </div>
  );

};
