import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Masonry from "@mui/lab/Masonry";
import moment from "moment";
import { MdDelete, MdAdd } from "react-icons/md";
import SlideInPanel from "../../Components/Modals/slideInPanel";
import ImageUploader from "../../Components/Common/ImageUpload";
import DeleteConfirmationModal from "../../Screens/MobileGallery/DeleteConfirmationModal";
import getErrorMessage from "../../Services/Helpers/getErrorMessage";
import { getMyGallery, deletePhoto } from "../../Services/APIHandlers/ProfileApi";
import { colors, globalStyles, spacingStyles } from "../../assets/styles/global";
import { useTheme } from "@mui/material/styles";

import { fetchMyGalleryAsync, categorizeByDate } from "../../Services/Redux/Slices/Gallery.Slice";


const CardItem = ({ image, picture_id, editEnabled, setDeleteId }) => (
  <div style={styles.imageCard}>
    <img src={image} style={{ borderRadius: 20, minHeight: 100}} alt="card" />
    {editEnabled && (
      <span style={styles.deleteButton} onClick={() => setDeleteId(picture_id)}>
        <MdDelete size={28} color={colors.white.color} />
      </span>
    )}
  </div>
);

const MasonaryCardView = ({ images, editEnabled = true, setDeleteId }) => {
  //const limitedImages = images.slice(0,4); // Limit the number of images displayed to 4
  return (
    <Masonry columns={{ xs: 1, sm: 1, md: 2, lg: 2} } spacing={2} >
      {images.map(({ url, picture_id }, index) => (
        <CardItem
          key={url + index}
          image={url}
          picture_id={picture_id}
          editEnabled={editEnabled}
          setDeleteId={setDeleteId}
        />
      ))}
    </Masonry>
  );
};

const OnboardingGallery = ({ editEnabled = true, gallery }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const token = useSelector((state) => state.auth.token);
  const { gallery: reduxGallery, loading } = useSelector((state) => state.gallery);

  // const [galleryData, setGalleryData] = useState([]);
  // const [loading, setLoading] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [isPanelOpen, setPanelOpen] = useState(false);

  // useEffect(() => {
  //   if (!gallery) {
  //     dispatch(fetchMyGalleryAsync({ token }));
  //   } else {
  //     dispatch(categorizeByDate(gallery));
  //   }
  // }, [dispatch, token, gallery]);

  const handleDelete = async () => {
    try {
      await deletePhoto({ token, picture_id: String(deleteId) });
      setDeleteId(null);
      dispatch(fetchMyGalleryAsync({ token }));
    } catch (error) {
      alert(getErrorMessage(error));
    }
  };


  const onLoadProfilePicSuccess = async () => {
    setPanelOpen(false);
    dispatch(fetchMyGalleryAsync({ token }));
  };

  // Categorize gallery by date
  const categorizedGallery = reduxGallery.reduce((acc, obj) => {

    const date = moment(obj.date).format("DD MMM yyyy");
    if (!acc[date]) acc[date] = [];
    if (obj.url) acc[date].push(obj);
    //console.log(acc);
    return acc;
  }, {});

  return (
    <div>
      {!!deleteId && (
        <DeleteConfirmationModal onDelete={handleDelete} onClose={() => setDeleteId(null)} />
      )}

      <div style={spacingStyles.w100}>
        {!Object.keys(categorizedGallery)?.length && !loading && (

          <div style={{ ...globalStyles.alignCenter, ...globalStyles.mt30 }}>
            <span style={{ ...globalStyles.h3, color: theme.palette.text.primary }}>
              No images added
            </span>
          </div>
        )}

        {Object.keys(categorizedGallery).map((key) => (
          <div key={key}>
          <div style={{ color: theme.palette.text.primary, textAlign: "left", marginBottom: '10px' }} >{key}</div>
            <div >
              <MasonaryCardView
                images={categorizedGallery[key]}
                editEnabled={editEnabled}
                setDeleteId={setDeleteId}
              />
            </div>
          </div>
        ))}
      </div>

      {editEnabled && (
        <>
          <div
            className="Add-Button"
            style={styles.addButton}
            onClick={() => setPanelOpen(true)}
          >
            <MdAdd color={colors.white.color} size={60} />
          </div>

          <SlideInPanel
            isOpen={isPanelOpen}
            closePanel={() => setPanelOpen(false)}
            children={<ImageUploader onSuccess={onLoadProfilePicSuccess} />}
          />
        </>
      )}
    </div>
  );
};

export default OnboardingGallery;

const styles = {
  addButton: {
    borderRadius: 60,
    position: "relative",
    margin: "auto",
    width: '60px',
    backgroundColor: colors.primary.color,
    alignSelf: "center",
    cursor: "pointer",
    marginTop: 20,
  },
  imageCard: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20, 
  },
  deleteButton: {
    backgroundColor: colors.gray.color,
    position: "absolute",
    bottom: 30,
    right: 10,
    borderRadius: 40,
    padding: 5,
    cursor: "pointer",
  },
};
