import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Stack,
    Typography,
    useTheme,
    Slider,
    IconButton,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AvatarEditor from "react-avatar-editor";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';  // for dispatching actions
import { addProfilePic } from "../../Services/Redux/Slices/User.Slice";
import { updateProfilePic } from "../../Services/APIHandlers/ProfileApi";
import config from '../../config';  // Assuming config contains the API URL
import AddIcon from '@mui/icons-material/Add';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

import ImageUploader from "../../Components/Common/ImageUpload";
import SlideInPanel from "../../Components/Modals/slideInPanel";

const ProfilePicUpload = () => {
    const [file, setFile] = useState(null);
    const [scale, setScale] = useState(1); // For zooming with react-avatar-editor
    const editorRef = useRef(null);

    const theme = useTheme();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const profilePic = useSelector((state) => state.user.profilepic);

    const role_id = auth.role_id;
    const isSquare = role_id === 3;
    const width = isSquare ? 250 : 640;
    const height = isSquare ? 250 : 360;

    const handleFileChange = (event) => {
        // const selectedFile = event.target.files[0];
        // setFile(selectedFile);
        setPanelOpen(true);
    };


    const [isUploading, setIsUploading] = useState(false);

    const handleUploadSuccess = async (picture_id) => {
        setIsUploading(false);
        await updateProfilePic(picture_id, auth.token);

        const currentHash = window.location.hash;
        window.location.href = window.location.pathname + currentHash;
        window.location.reload();

    };
    const [isPanelOpen, setPanelOpen] = useState(false);

    return (
        <Box>
            <Stack spacing={3} sx={{ textAlign: "left" }}>
                <Stack spacing={2}>
                    {!file && (
                        <>
                     
                        <div
                            style={{
                                backgroundImage: `url(${profilePic || require("../../assets/selfie.png")})`,
                                minHeight: "191px",
                                minWidth: '340px', 
                                display: "flex",

                                // paddingRight: '69%',
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                border: "2px dashed #8b8b8b",
                            }}
                        />
                            <Button
                                variant="outlined"
                                component="label"
                                startIcon={<AddToPhotosIcon />}
                            >
                                Upload Picture
                                <input type="button" hidden onClick={handleFileChange} />
                            </Button>
                        </>
                    )}
                </Stack>
                <SlideInPanel
                    isOpen={isPanelOpen}
                    closePanel={() => setPanelOpen(false)}
                    children={<ImageUploader onSuccess={handleUploadSuccess} />}
                />
            </Stack>
        </Box>
    );
};

export default ProfilePicUpload;
