import React from "react";
import { css } from "glamor";
import { ReactSVG } from "react-svg";
import { CustIcons } from "../../Services/Constants/customIcons";

const RenderIcons = ({ iconName, width=25, height=25, color }) => {
  const iconData = CustIcons[iconName];

  // console.log(iconName);
  // console.log(color);

  if (!iconData) {
    return null;
  }

  return (
    <div
      style={{
        fill: color,
        padding: 5,
        minWidth: '25px',
        maxWidth: `${width}px`, 
        maxHeight: `${height}px`,
      }}
    >
      <ReactSVG
        src={iconData.src.default}
        beforeInjection={(svg) => {
          svg.setAttribute("width", `${width}px`);
          svg.setAttribute("height", `${height}px`);
          svg.setAttribute("fill", color || "currentColor"); // Ensures color is applied
        }}
        style={{ width: "100%", height: "100%" }} // Ensures it fills the div properly
      />
    </div>
  );
};

export default RenderIcons;

export const styles = css({
  clr1: {
    fill: "white",
  },
  clr2: {
    fill: "white",
  },
});
