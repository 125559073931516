import React, { useEffect } from "react";
import { Box, Typography, Container, List, ListItem, ListItemIcon, useTheme } from "@mui/material";
import { FaCamera, FaInfoCircle, FaCalendarAlt } from 'react-icons/fa';
import { Button } from "../../../Components/Common/Buttons/Button";
import CleanWrapper from "../../../Components/Common/CleanWrapper";
import { useSelector } from "react-redux"; // Import useSelector to access Redux store

// Define copy for both roles in an easily editable array
const copy = {
  site: {
    title: "List Your Space",
    description: "We will guide you through the process.",
    steps: [
      { icon: <FaInfoCircle />, text: "Start with your Details." },
      { icon: <FaCalendarAlt />, text: "Set your availability and pricing." },
      { icon: <FaCamera />, text: "Lastly add Photos of your gym space." },
    ]
  },
  trainer: {
    title: "Create Your Hopper Profile",
    description: "Let’s get started with setting up your professional profile.",
    steps: [
      { icon: <FaCamera />, text: "Upload a profile picture." },
      { icon: <FaInfoCircle />, text: "Add your expertise and experience." },
      { icon: <FaCalendarAlt />, text: "Set your availability for clients." }
    ]
  }
};

const Setup = ({ goToStep, nextAction }) => {
  const theme = useTheme(); // Get the MUI theme (light or dark mode)

  // Use useSelector to get role_id from Redux store
  const role_id = useSelector((state) => state.auth.role_id);

  // Map the role_id to role type
  const role = role_id === 3 ? "trainer" : "site"; // Assuming 3 is trainer, and any other value is site

  // Select the copy based on the role
  const roleCopy = role === "trainer" ? copy.trainer : copy.site;

  // useEffect(() => {
  //   console.log("Setup Role_Id from Redux:", role_id, "3");
  // }, []);

  const handleGetStartedClick = () => {
    nextAction(); 
  };

  return (
    <CleanWrapper>
      <div className="two-col">
        <Box className="side-display">
          <Box
            component="img"
            alt="Login"
            src={theme.palette.mode === 'light'
              ? require('../../../assets/auth_display_light.jpg')
              : require('../../../assets/auth_display.jpg')}
            sx={{
              opacity: '50%',
              minHeight: '100%',
              objectFit: 'cover',
              overflow: 'hidden'
            }}
          />
        </Box>
        <Container
          sx={{
            
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box className="intro-details">
                <Typography
                  variant="h3"
                  color={theme.palette.text.primary} // Ensure text color changes with theme
                >
                  {roleCopy.title}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  color={theme.palette.text.secondary} // Set secondary text color
                >
                  {roleCopy.description}
                </Typography>

                <List sx={{ mb: 2, marginTop: "20px", padding: 0 }}>
                  {roleCopy.steps.map((step, index) => (
                    <ListItem key={index} sx={{ padding: 0 }}>
                      <ListItemIcon sx={{ minWidth: 0, marginRight: 1 }}>
                        {step.icon}
                      </ListItemIcon>
                      <Typography variant="body1" color={theme.palette.text.primary}>
                        {step.text}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>

              <Box className="onboarding-setup-cta" sx={{ mt: 2, width: "100%" }}>
                <Button
                  text={"Get Started"}
                  onClick={handleGetStartedClick} // Handle get started click based on the role
                  fill=""
                />
                <Typography
                  variant="body2"
                  sx={{ mt: 2 }}
                  color={theme.palette.text.primary} // Explicitly set color based on theme
                >
                  <strong>Got it. I am ready to get started.</strong>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
    </CleanWrapper>
  );
};

export default Setup;
