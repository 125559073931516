import React from "react";
import { IconButton, TextField, InputAdornment, Box, Typography } from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from "@mui/material/styles";
import { formStyles } from "../../assets/styles/global"; // Import global form styles
import { convertCentsToDollars } from "../../Services/Helpers/FinanceHelpers";
import { unset } from "lodash";

export default function RateInput({
  caption = "",
  timeUnit = "hour",
  chargeRate,
  handleChargeRateChange,
  incrementChargeRate,
  decrementChargeRate,
  isError = false,  // Optional prop to check for input error
}) {
  const theme = useTheme(); // Access the current theme

  // Conditionally use light/dark styles based on the theme mode
  const localStyle = {
    input: {
      ...formStyles.input,
      paddingRight: "20px",
      backgroundColor: "#ffffff26", // Dark mode background
      color: "#fff", // White text for dark mode
      fontSize: '35px',
      marginBottom: 'unset',
    },
    input_light: {
      ...formStyles.input_light,
      paddingRight: "20px",
      backgroundColor: "#0000001c", // Light mode background
      color: "#000", // Dark text for light mode
      fontSize: '50px',
      marginBottom: 'unset',
    },
  };
  const inputStyle = theme.palette.mode === 'light' ? localStyle.input_light : localStyle.input;

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', maxWidth: 316, width: '100%', justifyContent: 'center' }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto', alignItems: 'center' }}>
          <Typography variant="h4" color={theme.palette.text.secondary} sx={{ fontSize: 40, alignSelf: 'flex-start' }}>
            $
          </Typography>
          <TextField
            type="number"
            value={chargeRate !== null && chargeRate !== "" && !isNaN(chargeRate) ? convertCentsToDollars(chargeRate) : ""} 
            onChange={handleChargeRateChange}
            inputProps={{
              min: "",
              max: 1000,
              step: 1,
              style: { textAlign: 'left', ...inputStyle, }, // Apply global input style based on theme
            }}
            sx={{
              
              margin: '5px',
              minWidth: '85px',
              '& input': { textAlign: 'center' }, // Ensure input text is centered
              ...(isError ? formStyles.errorInput : {}), // Apply error style if error is present
            }}
            InputProps={{
              disableUnderline: true,
            }}
            variant="standard"
          />
          <Typography variant="h6" color={theme.palette.text.secondary} sx={{ fontSize: 24, alignSelf: 'flex-end' }}>
            /{timeUnit}
          </Typography>
        </Box>
        {/* <Box>
        <IconButton onClick={incrementChargeRate} sx={{ color: theme.palette.text.primary }}>
          <ArrowDropUpIcon />
        </IconButton>
        <IconButton onClick={decrementChargeRate} sx={{ color: theme.palette.text.primary }}>
          <ArrowDropDownIcon />
        </IconButton>
      </Box> */}

        {/* Error Text */}
        {isError && (
          <Typography sx={formStyles.errorText}>
            Please enter a valid rate.
          </Typography>
        )}

        <Typography variant="caption" sx={{ color: theme.palette.text.secondary, marginBottom: "20px", marginLeft: "35px"}}>
          {caption}
        </Typography>

      </Box>

    </>
  );
}
