import React, { useEffect, useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import StripeForm from './StripeForm';
import AuthContext from '../../Navigation/AuthWrapper';

import { getClientSubscriptionSecret, getPublishKey } from "../../Services/APIHandlers/CheckoutApi";
import { formStyles, spacingStyles } from '../../assets/styles/global';

import { Box } from '@mui/material';

const StripeSubscription = ({ subscriptionData, isOpen, mode }) => {

  const { plan, price, slug } = subscriptionData;
  const [options, setOptions] = useState(null);
  const [secretKey, setSecretKey] = useState(null);

  const [publishableKey, setPublishableKey] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Step 1: Get the Publishable Key
        const publishKeyResponse = await getPublishKey();
        if (!publishKeyResponse || !publishKeyResponse.data.publish_key) {
          console.error("Error retrieving Stripe publishable key");
          return;
        }

        const key = publishKeyResponse.data.publish_key;
        setPublishableKey(key);

        // Use the key directly instead of waiting for publishableKey state update
        const stripeInstance = await loadStripe(key);
        setStripePromise(stripeInstance);

        // Step 2: Get the Client Secret for Subscription
        const response = await getClientSubscriptionSecret({ plan, price, slug }, auth.token);
        if (!response || !response.data.client_secret) {
          console.error("Error retrieving Stripe client secret");
          return;
        }

        setSecretKey(response.data.client_secret);
        setupStripe(response.data.client_secret);

      } catch (err) {
        console.error(err);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const paymentElementStyle = {
    base: {
      ...formStyles.input,
    },
    invalid: {
      ...formStyles.input,
      ...formStyles.errorInput,
    },
  };

  const setupStripe = (client_secret) => {
    const optionsSetup = {
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      },
      clientSecret: client_secret,
      appearance: {
        variables: {
          fontFamily: ' "Gill Sans", sans-serif',
          fontLineHeight: '1.5',
          borderRadius: '2em',
          colorPrimary: '#ff6d00',
          colorBackground: '#F6F8FA',
          colorPrimaryText: '#262626',
        },
        rules: {
          '.Block': {
            backgroundColor: 'var(--color-primary)',
            boxShadow: 'none',
            padding: '12px',
          },
          '.Input': {
            backgroundColor: '#efefef',
            borderWidth: '1px',
            width: '-webkit-fill-available',
            padding: '15px',
            paddingLeft: '20px',
            fontSize: '18px',
            borderRadius: '25px',
            color: 'black',
            border: 'unset',
            outline: 'none',
          },
          '.Input--invalid': {
            backgroundColor: '#ff00004d',
            color: '#fff',
            borderWidth: '0',
            boxShadow: 'unset',
          },
          '.Tab': {
            backgroundColor: '#efefef',
            borderWidth: 1,
            width: '-webkit-fill-available',
            padding: '15px',
            paddingLeft: '20px',
            fontSize: '18px',
            color: 'black',
            border: 'unset',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '.Tab--selected': {
            boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
            backgroundColor: '#f47e3a47',
          },
          '.Label': {
            fontWeight: '500',
            color: 'black',
            textAlign: 'center',
            width: '100%',
          },
        },
      },
      style: paymentElementStyle,
    };
    setOptions(optionsSetup);
  };

  return (
    <Box sx={{ mb: 8 }} >
      <h2 style={{ marginBottom: '20px', color: mode === "light" ? "#000000" : "#FFFFFF" }}>Payment Method</h2>
      {stripePromise && secretKey && (
        <Elements stripe={stripePromise} options={options}>
          <StripeForm clientSecret={secretKey} />
        </Elements>
      )}
      <img
        className="security"
        style={{ ...spacingStyles.pt10 }}
        src={mode === "light" ? require('./../../assets/security_dark.png') : require('./../../assets/security.png')}
        alt="Security"
      />
    </Box>
  );
};

export default StripeSubscription;
