import { createTheme } from "@mui/material/styles";
import { unset } from "lodash";

export const colors = {
  primary: {
    color: "#FF6D00",
  },
  primary_dark: {
    color: "#8b4109",
  },
  primary_dark_light: {
    color: "#e76301",
  },
  primary_50: {
    color: "#ff6d0080",
  },
  primary_75: {
    color: "#ff6f00bb",
  },
  red: {
    color: "red",
  },
  red_50: {
    color: "#ff040480",
  },
  orange: {
    color: "#FF6D00",
  },
  green: {
    color: "#51FCBA",
  },
  greenrgb: {
    color: "rgb(81, 252, 186)",
  }, 
  green50: {
    color: "#51FCBA4F",
  },
  green50rgba: {
    color: "rgba(81, 252, 186, 0.31)",
  }, 
  green25: {
    color: "#51FCBA26",
  },
  green_dark:{
    color: '#169967',
  },
  yellow:{
    color: '#ffff00',
  },
  white: {
    color: "#fff",
  },
  white75: {
    color: "#ffffffba",
  },
  black: {
    color: "#000",
  },
  black75: {
    color: "#000000ba",
  },
  gray: {
    color: "#707070",
  },
  dark_gray: {
    color: "#525252",
  },
  darker_gray: {
    color: "#282828",
  },
  light_gray: {
    color: "#ADADAD",
  },
  light_input_back: {
    backgroundColor: "#f0f0f0",
  },
  card_gray: {
    color: "#1E1C21",
  },
  brown: {
    color: "#FF6D0088",
  },
  bl_18pct:{
    color: "#00000045", 
  },
  bl_44pct:{
  color: "#00000078",
  },
  wh_18pct: {
  color: "#ffffff45",
  }
};

export const themeColors = (theme) => ({
  primary: {
    color: "#FF6D00",
  },
  primary_dark: {
    color: "#8b4109",
  },
  primary_dark_light: {
    color: "#e76301",
  },
  primary_50: {
    color: "#ff6d0080",
  },
  primary_75: {
    color: "#ff6f00bb",
  },
  red: {
    color: "red",
  },
  red_50: {
    color: "#ff040480",
  },
  orange: {
    color: "#FF6D00",
  },
  green: {
    color: "#51FCBA",
  },
  greenrgb: {
    color: "rgb(81, 252, 186)",
  }, 
  green50: {
    color: "#51FCBA4F",
  },
  green50rgba: {
    color: "rgba(81, 252, 186, 0.31)",
  }, 
  green25: {
    color: "#51FCBA26",
  },
  green_dark:{
    color: theme.common.confirm.color,//'#169967',
  },
  yellow:{
    color: '#ffff00',
  },
  white: {
    color: "#fff",
  },
  white75: {
    color: "#ffffffba",
  },
  black: {
    color: "#000",
  },
  black75: {
    color: "#000000ba",
  },
  gray: {
    color: "#707070",
  },
  dark_gray: {
    color: "#525252",
  },
  darker_gray: {
    color: "#282828",
  },
  light_gray: {
    color: "#ADADAD",
  },
  light_input_back: {
    backgroundColor: "#f0f0f0",
  },
  card_gray: {
    color: "#1E1C21",
  },
  brown: {
    color: "#FF6D0088",
  },
});

export const globalStyles = {
  radius: {
    borderRadius: 10,
  },
  text: {
    color: "#fff",
  },
  gradientButton: {
    backgroundImage: "linear-gradient(90deg, #51FCBA, #026E44)",
    padding: "6px 17px",
    borderRadius: "8px",
    display: "inline-block",
  },

  gradientButtonText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#fff",
  },
  red: {
    backgroundColor: "red",
  },
  lightBlack: {
    backgroundColor: "#242424",
  },
  brightRed: {
    color: "#fc0818",
  },
  black: {
    color: "black",
  },
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  containerTopDwn: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "black",
  },
  fw600: {
    fontWeight: "600",
  },
  fw500: {
    fontWeight: "500",
  },
  centerView: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  input: {
    backgroundColor: "#FFFFFF26",
    width: "calc(100% - 20px)",
    padding: 14,
    fontSize: 18,
    borderRadius: 15,
    color: "white",
    display: "flex",
    alignItems: "center",
    height: "30%",
    textAlign: "left",
    overflow: "hidden",
  },
  errorInput: {
    backgroundColor: "#ff00004d",
    borderWidth: 0,
  },
  errorText: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },

  allCaps: {
    textTransform: "uppercase",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  h1: {
    color: "white",
    fontSize: 24,
    fontWeight: "400",
  },
  h2: {
    color: "white",
    fontSize: 20,
    fontWeight: "400",
  },
  h3: {
    color: "white",
    fontSize: 16,
    fontWeight: "300",
  },
  p: {
    color: "white",
  },
  textAlign: {
    textAlign: "center",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  label: {
    color: colors.primary.color,
    fontSize: 16,
    fontWeight: "300",
    textAlign: "left",
    width: "100%",
    marginBottom: 10,
  },
  center: {
    textAlign: "center",
  },
  alscenter: {
    alignSelf: "center",
  },
  flexStart: {
    justifyContent: "flex-start",
  },
  flexCenter: {
    justifyContent: "center",
  },
  flexSpace: {
    justifyContent: "space-between",
  },
  flexAround: {
    justifyContent: "space-around",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  flexEnd: {
    alignSelf: "flex-end",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  alignCenter: {
    alignItems: "center",
  },
  noLogHeader: {
    justifyContent: "flex-end",
    height: "25%",
  },
  logoHeader: {
    justifyContent: "center",
  },
  noLogLogo: {
    width: 145,
    height: 128,
    marginBottom: 5,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  headerLogo: {
    width: 160,
    height: 70,
    marginBottom: 5,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  noLogFavicon: {
    width: 60,
    height: 60,
    marginBottom: 5,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  noLogContent: {
    justifyContent: "flex-start",
    paddingTop: "25%",
    alignItems: "center",
    height: "60%",
    width: "80%",
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    color: "white",
    fontSize: 18,
  },
  orange: {
    color: "#FF6D00",
  },
  green: {
    color: "#51FCBA",
  },
  yellow: {
    color: "#FFE135",
  },
  white: {
    color: "#fff",
  },
  dark_gray: {
    color: "#525252",
  },
  gray: {
    color: "#707070",
  },
  light_gray: {
    color: "#ADADAD",
  },
  w20: {
    width: "20%",
  },
  w30: {
    width: "30%",
    alignItems: "center",
  },
  w33: {
    width: "33.3%",
    alignItems: "center",
  },
  w50: {
    width: "50%",
    alignItems: "center",
  },
  w80: {
    width: "80%",
  },
  w95: {
    width: "95%",
  },
  w100: {
    width: "100%",
  },

  w19: {
    width: "19%",
  },
  ht10: {
    height: "10%",
  },
  ht9: {
    height: "13%",
  },
  ht15: {
    height: "15%",
  },
  ht20: {
    height: "20%",
  },
  ht30: {
    height: "30%",
  },
  ht35: {
    height: "35%",
  },
  ht40: {
    height: "40%",
  },
  ht50: {
    height: "50%",
  },
  ht60: {
    height: "60%",
  },
  ht65: {
    height: "65%",
  },
  ht70: {
    height: "70%",
  },
  ht80: {
    height: "80%",
  },
  ht85: {
    height: "85%",
  },
  ht100: {
    height: "100%",
  },
  m5: {
    margin: 5,
  },
  m20: {
    margin: 20,
  },
  mh2: {
    marginLeft: 2,
    marginRight: 2,
  },
  mh5p: {
    marginLeft: "5%",
    marginRight: "5%",
  },
  mt10: {
    marginTop: 10,
  },
  mt20: {
    marginTop: 20,
  },
  mt30: {
    marginTop: 30,
  },
  mt40: {
    marginTop: 40,
  },
  mt50: {
    marginTop: 40,
  },
  mt60: {
    marginTop: 60,
  },
  mb5: {
    marginBottom: 5,
  },
  mb10: {
    marginBottom: 10,
  },
  mb20: {
    marginBottom: 20,
  },
  mb40: {
    marginBottom: 40,
  },

  mb15p: {
    marginBottom: "15%",
  },
  mb35p: {
    marginBottom: "35%",
  },
  ml5: {
    marginLeft: 5,
  },
  ml10: {
    marginLeft: 10,
  },
  mr5: {
    marginRight: 5,
  },
  mr15: {
    marginRight: 15,
  },
  mr3p: {
    marginRight: "3%",
  },
  mr7p: {
    marginRight: "7%",
  },
  ml7p: {
    marginLeft: "7%",
  },
  p3: {
    padding: 3,
  },
  p5: {
    padding: 5,
  },
  p10: {
    padding: 10,
  },
  p20: {
    padding: 20,
  },
  pl5: {
    paddingLeft: 5,
  },
  pl10: {
    paddingLeft: 10,
  },
  plr10: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  ph40: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  ph20: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  pr10: {
    paddingRight: 10,
  },
  pb5: {
    paddingBottom: 5,
  },
  pb10: {
    paddingBottom: 10,
  },
  pb20: {
    paddingBottom: 20,
  },
  pb40: {
    paddingBottom: 40,
  },
  pb60: {
    paddingBottom: 60,
  },
  pb100: {
    paddingBottom: 100,
  },
  pb120: {
    paddingBottom: 120,
  },
  pb140: {
    paddingBottom: 140,
  },
  pb25pct: {
    paddingBottom: "25%",
  },
  pb35pct: {
    paddingBottom: "35%",
  },
  pb50pct: {
    paddingBottom: "50%",
  },
  pt5: {
    paddingTop: 5,
  },
  pt10: {
    paddingTop: 10,
  },
  pt20: {
    paddingTop: 20,
  },
  pt40: {
    paddingTop: 40,
  },
  pt60: {
    paddingTop: 60,
  },
  tabBar: {
    display: "flex",
    position: "absolute",
    width: "60%",
    right: "17.5%",
    left: "22.5%",
    bottom: 30,
    borderRadius: 20,
    height: 50,
    backgroundColor: "#fff",
    borderTopWidth: 0,
  },
  tabIconContainer: {
    alignItems: "center",
    justifyContent: "center",
    top: 15,
  },
  tabIcon: {
    width: 25,
    height: 25,
  },
  myLocalIcon: {
    width: 50,
    height: 50,
  },
  tabHighlightIcon: {
    width: 75,
    height: 75,
    position: "absolute",
    top: -15,
    left: -15,
  },
  shadow: {
    shadowColor: "#7F5DF0",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.5,
    elevation: 5,
  },
  bbo: {
    borderBottomColor: "#FF6D00",
    borderBottomWidth: 2,
    marginBottom: 30,
    paddingBottom: 10,
    borderBottomStyle: "solid",
  },
  bbg: {
    borderBottomColor: colors.dark_gray.color,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
  bb0: {
    borderBottomColor: "unset",
  },
  br10: {
    borderRadius: 10,
  },
  cust_header: {
    justifyContent: "flex-end",
    height: "25%",
  },
  badgesContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  badge: {
    backgroundColor: "#32900F80",
    borderColor: "#32900F",
    borderWidth: 1,
  },
  dropdown_btn: {
    backgroundColor: "#FFFFFF26",
    borderRadius: 10,
    width: "100%",
  },
  dropdown_text: {
    color: "white",
  },
  dropdown: {
    backgroundColor: "#FFFFFF33",
  },
  obtn: {
    backgroundColor: "#FF6D00",
    width: "70%",
    height: 50,
    borderRadius: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "500",
    marginLeft: 10,
  },
  gbtn: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "gray",
    marginRight: 10,
    marginLeft: 10,
    marginTop: 20,
    paddingTop: "5%",
    paddingBottom: "5%",
    borderRadius: 20,
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  fw400: {
    fontWeight: "400",
  },
  grbdr: {
    borderColor: "#707070",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 50,
    padding: 10,
    textAlign: "center",
    alignItems: "center",
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
  securityImage: {
    position: "relative",
    width: "100%",
    maxWidth: "25%",
  },
  card: {
    padding: 20,
    borderRadius: 10,
    flexDirection: "column",
    backgroundColor: "#242424",
  },
};

export const baseTheme = createTheme({
  typography: {
    fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    fontSize: 14,
    body: {
      color: 'yourColorChoice',  // Change the color globally for body1
      padding: '10px 0 20px',    // Add custom padding
    },
  },
  common: {
    white: colors.white
  },
});

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'light',
    primary: {
      light: colors.primary_75.color,
      main: colors.primary.color,
      dark: colors.primary_dark_light.color,
      contrastText: colors.white.color,
    },
    secondary: {
      light: colors.red_50.color,
      main: colors.orange.color,
      dark: colors.red.color,
      contrastText: colors.black.color,
    },
    background: {
      default: colors.light_input_back.backgroundColor,
      paper: colors.white.color,
      halfpaper: colors.white75.color,
      opposite: colors.black75.color,
    },
    text: {
      primary: colors.darker_gray.color,
      secondary: colors.dark_gray.color,
      disabled: colors.gray.color,
      opposite: colors.white,
    },
    gradient: {
      confirm: `linear-gradient(to bottom, ${colors.green_dark.color}, ${colors.green_dark.color}, ${colors.green_dark.color})`,
      warning: `linear-gradient(to top, ${colors.red.color}, ${colors.red.color}, ${colors.red.color})`,
      //main: 'linear-gradient(to right, #43cea2, #185a9d)', // Define your gradient
    },
  },
  shape: {
    borderRadius: globalStyles.radius.borderRadius,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          backgroundColor: colors.primary.color,
          color: colors.white.color,
          '&:hover': {
            backgroundColor: colors.primary_50.color,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 8,  // Add border radius to Snackbar
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Subtle shadow
          padding: '0 16px', // Add padding to the Snackbar
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '1rem', // Change font size for alert content
          borderRadius: 8,  // Rounded corners for alerts
          padding: '12px 16px', // Add custom padding
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Custom shadow for alerts
        },
        standardWarning: {
          backgroundColor: '#f8db2f', // Custom warning background color
          color: '#000',              // Custom warning text color
        },
        standardSuccess: {
          backgroundColor: '#4CAF50', // Custom success background color
          color: '#FFF',              // Custom success text color
        },
        standardError: {
          backgroundColor: '#F44336', // Custom error background color
          color: '#FFF',              // Custom error text color
        },
        standardInfo: {
          backgroundColor: '#2196F3', // Custom info background color
          color: '#FFF',              // Custom info text color
        },
      },
    },
  },
  shadows: [
    'none', // shadow[0]
    '0px 2px 4px rgba(0, 0, 0, 0.1)', // shadow[1]
    '0px 4px 8px rgba(0, 0, 0, 0.15)', // shadow[2]
    '0px 6px 12px rgba(0, 0, 0, 0.2)', // shadow[3]
    '0px 8px 16px rgba(0, 0, 0, 0.25)', // shadow[4]
    '0px 10px 20px rgba(0, 0, 0, 0.3)', // shadow[5]
    '0px 12px 24px rgba(0, 0, 0, 0.35)', // shadow[6]
    '0px 14px 28px rgba(0, 0, 0, 0.4)', // shadow[7]
    '0px 16px 32px rgba(0, 0, 0, 0.45)', // shadow[8]
    // Continue defining as needed up to 24 levels or beyond
  ],
  common: {
    availableGray: colors.gray,
    confirm: colors.green_dark
  },
});

export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: {
      light: colors.primary_75.color,
      main: colors.primary.color,
      dark: colors.primary_dark.color,  
      contrastText: colors.white.color,
    },
    secondary: {
      light: colors.red_50.color,
      main: colors.orange.color,
      dark: colors.red.color,
      contrastText: colors.white.color,
    },
    background: {
      default: colors.darker_gray.color,
      paper: colors.black.color,
      halfpaper: colors.black75.color,
      opposite: colors.white75.color,
    },
    text: {
      primary: colors.white.color,
      secondary: colors.light_gray.color,
      disabled: colors.gray.color,
      opposite: colors.black,
    },
    gradient: {
      confirm: `linear-gradient(to bottom, ${colors.green_dark.color}, ${colors.green_dark.color}, ${colors.green_dark.color})`,
      warning: `linear-gradient(to top, ${colors.red.color}, ${colors.red.color}, ${colors.red.color})`,
      //main: 'linear-gradient(to bottom, #43cea2, #185a9d)', // Define your gradient
    },
  },
  shape: {
    borderRadius: globalStyles.radius.borderRadius,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: globalStyles.radius.borderRadius,
          backgroundColor: colors.primary.color,
          color: colors.white.color,
          '&:hover': {
            backgroundColor: colors.primary_50.color,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 8,  // Add border radius to Snackbar
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Subtle shadow
          padding: '0 16px', // Add padding to the Snackbar
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '1rem', // Change font size for alert content
          borderRadius: 8,  // Rounded corners for alerts
          padding: '12px 16px', // Add custom padding
         // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Custom shadow for alerts
        },
        standardWarning: {
          backgroundColor: colors.yellow.color, // Custom warning background color
          color: '#000',              // Custom warning text color
        },
        standardSuccess: {
          backgroundColor: '#4CAF50', // Custom success background color
          color: '#FFF',              // Custom success text color
        },
        standardError: {
          backgroundColor: '#F44336', // Custom error background color
          color: '#FFF',              // Custom error text color
        },
        standardInfo: {
          backgroundColor: '#2196F3', // Custom info background color
          color: '#FFF',              // Custom info text color
        },
      },
    },
  },
  shadows: [
    'none', // shadow[0] - no shadow
    '0px 0px 8px rgba(255, 255, 255, 0.25)', // shadow[1] - subtle white glow
    '2px 2px 16px rgba(255, 255, 255, 0.15)', // shadow[2] - larger subtle white glow
    '0px 0px 12px rgba(255, 255, 255, 0.7)', // shadow[3] - stronger white glow
    '0px 0px 15px rgba(81, 252, 186, 0.7)',  // shadow[4] - green glow
    '0px 0px 20px rgba(255, 165, 0, 0.8)',   // shadow[5] - orange glow
    '0px 0px 25px rgba(255, 69, 0, 0.8)',    // shadow[6] - red-orange glow
    '0px 0px 30px rgba(0, 206, 209, 0.8)',   // shadow[7] - cyan glow
   // '0px 0px 35px rgba(138, 43, 226, 0.85)', // shadow[8] - violet glow
  ],
  common: {
    availableGray: colors.darker_gray,
    confirm: colors.green,
  },
});

export const spacingStyles = {
  desktopMaxWidth: {
    // padding: "0 20px",
    maxWidth: "640px",
    // height: "140%", 
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    marginBottom: '135px',
    // '@media (max-width: 640px)': {
    //   width: "95%",
    // },
  },
  desktopMaxWidthTwoCol: {
    position: "relative", 
    width: "100%",
    maxWidth: "1080px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: '60px',
    padding: "0 20px", 
    // '@media (max-width: 640px)': {
    //   width: "95%",
    // },
  },
  onboardingMaxWidth: {
    maxWidth: "440px",
    marginLeft: "auto",
    marginRight: "auto",
    // width: "90%",
    // '@media (max-width: 640px)': {
    //   width: "95%",
    // },
  },
  formSpace: {
    maxWidth: "440px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  minw300: {
    minWidth: "300px",
  },
  w20: {
    width: "20%",
  },
  w30: {
    width: "30%",
    alignItems: "center",
  },
  w33: {
    width: "33.3%",
    alignItems: "center",
  },
  w50: {
    width: "50%",
    alignItems: "center",
  },
  w80: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  w95: {
    width: "95%",
  },
  w100: {
    width: "100%",
  },
  w25w: {
    width: "25vw",
  },
  w35w: {
    width: "35vw",
  },

  w65w: {
    width: "65vw",
  },

  w75w: {
    width: "75vw",
  },
  w100w: {
    width: "100vw",
  },
  w300px: {
    width: "300px",
  },
  w19: {
    width: "19%",
  },
  wcalc_mobile: {
    width: "calc(100vw - 40px)",
  },
  ht10: {
    height: "10%",
  },
  ht9: {
    height: "13%",
  },
  ht15: {
    height: "15%",
  },
  ht20: {
    height: "20%",
  },
  ht30: {
    height: "30%",
  },
  ht35: {
    height: "35%",
  },
  ht40: {
    height: "40%",
  },
  ht50: {
    height: "50%",
  },
  ht60: {
    height: "60%",
  },
  ht65: {
    height: "65%",
  },
  ht70: {
    height: "70%",
  },
  ht80: {
    height: "80%",
  },
  ht85: {
    height: "85%",
  },
  ht90: {
    height: "90vh",
  },
  ht100: {
    height: "100%",
  },
  mauto: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  m5: {
    margin: 5,
  },
  mh2: {
    marginLeft: 2,
    marginRight: 2,
  },
  mh5p: {
    marginLeft: "5%",
    marginRight: "5%",
  },
  mt0: {
    marginTop: 0,
  },
  mt5: {
    marginTop: 5,
  },
  mt10: {
    marginTop: 10,
  },
  mt10h: {
    marginTop: "10vh",
  },
  mt20: {
    marginTop: "20px",
  },
  mt30: {
    marginTop: 30,
  },
  mt40: {
    marginTop: "40px",
  },
  mt60: {
    marginTop: 60,
  },
  mb0: {
    marginBottom: "unset",
  },
  mb5: {
    marginBottom: 5,
  },
  mb10: {
    marginBottom: 10,
  },
  mt15: {
    marginTop: 15,
  },
  m20: {
    margin: '20px',
  },
  mb20: {
    marginBottom: '20px',
  },
  mb20h: {
    marginBottom: "20vh",
  },
  mb40: {
    marginBottom: 40,
  },

  mb15p: {
    marginBottom: "15%",
  },
  mb35p: {
    marginBottom: "35%",
  },
  ml5: {
    marginLeft: 5,
  },
  ml10: {
    marginLeft: 10,
  },
  ml20: {
    marginLeft: 20,
  },
  mr5: {
    marginRight: 5,
  },
  mr20: {
    marginRight: 20,
  },
  mlr5: {
    marginLeft: 5,
    marginRight: 5,
  },
  mlrauto: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mr3p: {
    marginRight: "3%",
  },
  mr7p: {
    marginRight: "7%",
  },
  munset: {
    margin: "unset",
  },
  punset: {
    padding: "unset",
  },
  p3: {
    padding: 3,
  },
  p5: {
    padding: 5,
  },
  p10: {
    padding: 10,
  },
  pl5: {
    paddingLeft: 5,
  },
  pl10: {
    paddingLeft: 10,
  },
  pl20: {
    paddingLeft: 20,
  },
  plr10: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  plr20: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  ph40: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  ph20: {
    paddingLeft: 20,
  },

  pr10: {
    paddingRight: 10,
  },
  pb5: {
    paddingBottom: 5,
  },
  pb10: {
    paddingBottom: 10,
  },
  pb20: {
    paddingBottom: 20,
  },
  pb25h: {
    paddingBottom: "25vh",
  },

  pb50h: {
    paddingBottom: "50vh",
  },
  pb40: {
    paddingBottom: "40px",
  },
  pb60: {
    paddingBottom: 60,
  },
  pb100: {
    paddingBottom: 100,
  },
  pb120: {
    paddingBottom: 120,
  },
  pb140: {
    paddingBottom: 140,
  },
  pb25pct: {
    paddingBottom: "25%",
  },
  pb35pct: {
    paddingBottom: "35%",
  },
  ptb5: {
    padding: '5px 0',
  },
  pt0: {
    paddingTop: 0,
  },
  pt5: {
    paddingTop: 5,
  },
  pt5h: {
    paddingTop: "5vh",
  },
  pt10h: {
    paddingTop: "10vh",
  },
  pt15h: {
    paddingTop: "15vh",
  },
  pt20h: {
    paddingTop: "20vh",
  },
  pt10: {
    paddingTop: 10,
  },
  pt20: {
    paddingTop: 20,
  },
  pt40: {
    paddingTop: 40,
  },
  pt50: {
    paddingTop: 50,
  },
  pt100: {
    paddingTop: 100,
  },
};

export const formStyles = {
  input: {
    borderWidth: "1px",
    width: "-webkit-fill-available",
    // display: "inline-block",
    // padding: "15px",
    paddingLeft: "20px",
    fontSize: "18px",
    borderRadius: "25px",
    color: "white",
    border: "unset",
    outline: "none",
    marginBottom: "20px",
  },
  input_light: {
    // backgroundColor: "#dedede",
    borderWidth: "1px",
    width: "-webkit-fill-available",
    // display: "inline-block",
    // padding: "15px",
    paddingLeft: "20px",
    // fontSize: "18px",
    borderRadius: "25px",
    color: "black",
    border: "unset",
    outline: "none",
  },
  errorInput: {
    backgroundColor: "#ff00004d",
    borderWidth: "0",
  },
  errorText: {
    color: "var(--color-danger)",
    textAlign: "center",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "16px", // Change this value to adjust the radius
    },
    "& .MuiInputLabel-root": {
      borderRadius: "16px", // Change the label's radius if needed
    },
  },
};

export const calendarStyles = {
  month_day: {
    color: colors.light_gray.color,
    // fontWeight: '600',
    fontSize: 16,
    padding: 7,
  },
  short_day: {
    color: "white",
    fontWeight: "600",
    fontSize: 20,
  },
  pastDay: {
    backgroundColor: colors.gray.color,
    color: colors.black.color,
  },
  comingDay: {
    // backgroundColor: 'unset',
    color: colors.white.color,
    fontWeight: 600,
  },
  availableDay: {
    backgroundColor: colors.dark_gray.color,
    color: "white",
    fontWeight: 700,
  },
  blackoutDay: {
    backgroundColor: colors.darker_gray.color,
    color: colors.gray.color,
    padding: 10,
    borderRadius: 50,
  },
  weekContainer: {
    flexDirection: "col",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  emptyDay: {
    borderRadius: 50,
    overflow: "hidden",
    height: "2rem",
    width: "2rem",
    borderWidth: 0,
    borderStyle: "unset",
    marginBottom: "5px",
    backgroundColor: colors.black.color,
  },
  emptyTime: {
    borderWidth: 0,
    borderStyle: "unset",
    borderRadius: "10px",
    padding: "3px",
    marginBottom: "5px",
    marginRight: "2px",
    marginLeft: "2px",
  },
  activeMonth: {
    width: "30%",
    alignItems: "center",
    backgroundColor: "orange",
    borderRadius: 50,
    padding: 10,
  },
  month: {
    width: "30%",
    alignItems: "center",
    borderColor: "gray",
    borderWidth: 2,
    color: "gray",
    borderRadius: 50,
    padding: 10,
  },
  today: {
    backgroundColor: colors.primary_50.color,
    color: "white",
    fontWeight: 700,
  },
  selectDay: {
    backgroundColor: colors.green50.color,
    color: colors.white.color,
    fontWeight: 700,
    fontSize: 14,
    padding: 5,
    borderColor: colors.green.color,
    borderStyle: "solid",
    borderRadius: 1,
    borderWidth: 2,
  },
  selectTime: {
    fontSize: "18px",
    fontWeight: "700",
    borderColor: colors.green.color,
    borderStyle: "solid",
    borderRadius: 50,
    borderWidth: 2,
    backgroundColor: colors.green50.color,
    padding: 'unset',
  },
  selectRange: {
    color: "#fff",
    tintColor: "#fff",
    fontWeight: 700,
  },
  timeIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  day: {
    backgroundColor: "#000",
    borderColor: colors.gray.color,
    borderWidth: 1,
    borderRadius: 10,
  },

  hasDay: {
    backgroundColor: "#51FCBA4F",
    borderColor: colors.green.color,
    borderWidth: 1,
  },
  hasRange: {
    backgroundColor: "#51FCBA4F",
    borderColor: colors.green.color,
    borderWidth: 1,
  },
  rangeContaner: {
    alignItems: "center",
    flexBasis: "30%",
    backgroundColor: "#000",
    borderColor: colors.gray.color,
    borderWidth: 1,
    margin: "1.5%",
    borderRadius: 10,
    justifyContent: "center",
  },
  icon: {
    tintColor: colors.gray.color,
  },
  selectIcon: {
    tintColor: "#fff",
  },
  range: {
    alignItems: "center",
    flexBasis: "30%",
    backgroundColor: "#000",
    borderColor: colors.gray.color,
    borderWidth: 1,
    margin: "1.5%",
  },
  time: {
    color: colors.gray.color,
  },

  availableTime: {
    backgroundColor: colors.dark_gray.color,
    borderRadius: 10,
    color: colors.white.color,
  },
  availableTimeText: {
    color: colors.white.color,
  },
  selectTimeText: {
    color: colors.white.color,
    fontSize: 18,
    fontWeight: 600,
  },

  legendContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  colorSquare: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: 8,
  },
  label: {
    fontSize: 16,
  },
  rangeLabel: {
    fontSize: 16,
    ...colors.gray,
  },
};
export const calendar = (theme) => ({
  month_day: {
    color: theme.palette.text.primary,
    // fontWeight: '600',
    fontSize: 16,
    padding: 7,
  },
  short_day: {
    color: theme.palette.text.primary,
    fontWeight: "600",
    fontSize: 20,
  },
  pastDay: {
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.text.opposite,
  },
  comingDay: {
    // backgroundColor: 'unset',
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  availableDay: {
    backgroundColor: theme.common.availableGray.color,
    color: "white",
    fontWeight: 700,
  },
  blackoutDay: {
    backgroundColor: colors.darker_gray.color,
    color: colors.gray.color,
    padding: 10,
    borderRadius: 50,
  },
  weekContainer: {
    flexDirection: "col",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  emptyDay: {
    borderRadius: 50,
    overflow: "hidden",
    height: "2rem",
    width: "2rem",
    borderWidth: 0,
    borderStyle: "unset",
    marginBottom: "5px",
    backgroundColor: colors.black.color,
  },
  emptyTime: {
    borderWidth: 0,
    borderStyle: "unset",
    borderRadius: "10px",
    padding: "3px",
    marginBottom: "5px",
    marginRight: "2px",
    marginLeft: "2px",
  },
  activeMonth: {
    width: "30%",
    alignItems: "center",
    backgroundColor: "orange",
    borderRadius: 50,
    padding: 10,
  },
  month: {
    width: "30%",
    alignItems: "center",
    borderColor: "gray",
    borderWidth: 2,
    color: "gray",
    borderRadius: 50,
    padding: 10,
  },
  today: {
    backgroundColor: colors.primary_50.color,
    color: "white",
    fontWeight: 700,
  },
  selectDay: {
    backgroundColor: colors.green_dark.color,
    color: colors.white.color,
    fontWeight: 700,
    fontSize: 14,
    padding: 5,
    borderColor: colors.green.color,
    borderStyle: "solid",
    borderRadius: 1,
    borderWidth: 2,
  },
  selectTime: {
    fontSize: "18px",
    fontWeight: "700",
    borderColor: colors.green.color,
    borderStyle: "solid",
    borderRadius: 50,
    borderWidth: 2,
    backgroundColor: colors.green_dark.color,
    padding: 'unset',
  },
  selectRange: {
    color: "#fff",
    tintColor: "#fff",
    fontWeight: 700,
  },
  timeIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  day: {
    backgroundColor: "#000",
    borderColor: colors.gray.color,
    borderWidth: 1,
    borderRadius: 10,
  },

  hasDay: {
    backgroundColor: "#51FCBA4F",
    borderColor: colors.green.color,
    borderWidth: 1,
  },
  hasRange: {
    backgroundColor: "#51FCBA4F",
    borderColor: colors.green.color,
    borderWidth: 1,
  },
  rangeContaner: {
    alignItems: "center",
    flexBasis: "30%",
    backgroundColor: "#000",
    borderColor: colors.gray.color,
    borderWidth: 1,
    margin: "1.5%",
    borderRadius: 10,
    justifyContent: "center",
  },
  icon: {
    tintColor: colors.gray.color,
  },
  selectIcon: {
    tintColor: "#fff",
  },
  range: {
    alignItems: "center",
    flexBasis: "30%",
    backgroundColor: "#000",
    borderColor: colors.gray.color,
    borderWidth: 1,
    margin: "1.5%",
  },
  time: {
    color: theme.palette.text.secondary,
  },

  availableTime: {
    backgroundColor: theme.common.availableGray.color,
    borderRadius: 10,
    color: colors.white.color,
  },
  availableTimeText: {
    color: colors.white.color,
  },
  selectTimeText: {
    color: colors.white.color,
    fontSize: 18,
    fontWeight: 600,
  },

  legendContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  colorSquare: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: 8,
  },
  label: {
    fontSize: 16,
  },
  rangeLabel: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
});


