import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";

import { IoIosCheckmarkCircle } from "react-icons/io";
import { CircularProgress } from "@mui/material";
import { spacingStyles, colors } from "../../assets/styles/global";
import "../../assets/styles/global.css";

import config from "../../config";

import heic2any from "heic2any";

const ImageUploader = ({ ratio = 1, onSuccess }) => {

  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    minWidth: 160,
    minHeight: 90,
    aspect: 1.778,
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  // useEffect(()=>{
  //   console.log("selectedImage: ", selectedImage);
  // },[]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.warn("No file selected");
      return;
    }

    const validExtensions = ["image/jpeg", "image/png", "image/heic"];

    if (!validExtensions.includes(file.type)) {
      alert("Only JPG, PNG, and HEIC images are allowed.");
      event.target.value = ""; // Reset input
      return;
    }

    let imageSrc;
    let processedFile = file;

    // Convert HEIC to JPG
    if (file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
        });

        imageSrc = URL.createObjectURL(convertedBlob);
        processedFile = new File([convertedBlob], file.name.replace(/\.heic$/i, ".jpg"), {
          type: "image/jpeg",
        });
      } catch (error) {
        console.error("HEIC conversion error:", error);
        alert("Failed to process HEIC image.");
        return;
      }
    } else {
      imageSrc = URL.createObjectURL(file);
    }

    setSelectedImage(processedFile);
    setPreviewImage(imageSrc);
  };

  const handleUpload = async () => {

    setIsUploading(true);

    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("token", token);

      // Add other data to formData if needed
      // formData.append('directory', 'your_directory');

      try {
        const response = await fetch(
          config.apiUrl + "Upload/singlePhotoUpload",
          {
            method: "POST",
            body: formData,
          }
        );

        console.log(response);

        if (response.ok) {
          const result = await response.json();
          console.log("Upload successful:", result);
          // Handle the response as needed
          setIsUploading(false);
          onSuccess(result.data.picture_id);
          //console.log(onSuccess());
          setSelectedImage(null);
        } else {
          alert("Loading Error. Max Picture Size is 2.5MB");
          console.error("Upload failed:", response.statusText);
          window.location.reload();
          // Handle the error
        }

        setCrop(crop);

      } catch (error) {
        console.error("Error during upload:", error.message);
        // Handle the error
      }
    } else {
      console.warn("No image selected");
    }
  };

  return (
    <div>
      {selectedImage && (
        <div>
          <ReactCrop
            crop={crop}
            onChange={(c) => {
              c.aspect = 1.778;
              c.unit = "px";
              setCrop(c);
            }}
          >
            <img src={previewImage} alt="crop" />
          </ReactCrop>
        </div>
      )}

      {!selectedImage && (
        <div className="upload-box">
          <label className="upload-box-label flexCol flexCenter">
            <PhotoCameraBackIcon sx={{ fontSize: 80 }} />
            <input type="file"
              accept=".jpg,.jpeg,.png,.heic,image/jpeg,image/png,image/heic"
              onChange={handleImageChange} />
            <i className="fa fa-cloud-upload"></i>Upload Image
          </label>
        </div>
      )}

      {/* {previewImage && (
        <div>
          <p>Preview</p>
          <img src={previewImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </div>
      )} */}
      {/* {selectedImage && (
        <div className="upload-confirm">
          <IoIosCheckmarkCircle
            size={37}
            color={colors.green.color}
            onClick={handleUpload}
          ></IoIosCheckmarkCircle>
        </div>
      )} */}

      {selectedImage && (
        <div className="upload-confirm">
          {isUploading ? (
            <CircularProgress size={37} color="success" />
          ) : (
            <IoIosCheckmarkCircle size={37} color={colors.green.color} onClick={handleUpload} />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
