import React, { useState, useEffect, useContext, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Button, CircularProgress } from "@mui/material";

import GymDetailsHeader from "../../Components/Gym/GymDetailsHeader";
import StripePayment from "../../Components/Forms/StripePayment";
import StripeSubscription from "../../Components/Forms/StripeSubscription";
import AuthContext from "../../Navigation/AuthWrapper";

import RegisterForm from "../../Components/Forms/RegisterForm";
import LoginForm from "../../Components/Forms/LoginForm";
import OTPForm from "../../Components/Forms/OTPForm";

import { spacingStyles, colors, formStyles } from "../../assets/styles/global";
import { getTechFee } from "../../Services/APIHandlers/CheckoutApi";

import LeaseAgreement from "../../Components/Documents/LeaseAgreement";

export const SubscriptionCheckout = ({ subscriptionData, isOpen, mode, subscriptionOption, paymentFrequency }) => {


    const selectedSite = useSelector((state) => state.booking.selectedSite, shallowEqual);
    const [chargeRate, setChargeRate] = useState(Number(selectedSite.details.space.chargeRate));
    const [bookingFEE, setBookingFEE] = useState(500);
    const userData = JSON.parse(localStorage.getItem("user"));


    // useEffect(() => {
    //     console.log(selectedSite);
    //     console.log(subscriptionData);
    //     console.log(userData);

    // }, []);


    const { plan, price, slug } = subscriptionData;
    const theme = useTheme();
    const checkoutRef = useRef(null);
    const paymentRef = useRef(null);
    const auth = useContext(AuthContext);

    const textStyle =
        mode === "light"
            ? { color: colors.black, inputStyles: formStyles.input_light }
            : { inputStyles: formStyles.input };


    const [isRegistered, setIsRegistered] = useState(false);
    const [isOTPConfirmed, setIsOTPConfirmed] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [authToggle, setAuthToggle] = useState("Login");

    useEffect(() => {
        //console.log(subscriptionOption, selectedSite.details.space, paymentFrequency);
        let baseRate = 0;

        // Determine base rate based on the subscription type
        switch (subscriptionOption) {
            case 'Instant 1-on-1':
                baseRate = Number(selectedSite.details.space.chargeRate);
                break;
            case 'Instant Group':
                baseRate = Number(selectedSite.details.space.chargeRateGroup);
                break;
            case 'Monthly 1-on-1':
                baseRate = Number(selectedSite.details.space.monthlyRate);
                break;
            case 'Monthly Group':
                baseRate = Number(selectedSite.details.space.monthlyRateGroup);
                break;
            default:
                break;
        }

        // Adjust the rate based on the payment frequency
        const multipliers = {
            "monthly": 1,
            "bi-weekly": 0.5,
            "weekly": 0.25
        };

        const adjustedRate = baseRate * (multipliers[paymentFrequency] || 1);

        setChargeRate(adjustedRate);
    }, [subscriptionOption, selectedSite.details.space, paymentFrequency]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getTechFee(chargeRate);
                //console.log(response.data);
                setBookingFEE(response.data.fee);
            } catch (err) {
                console.error(err);
            }
        }
        fetchData();
    }, [chargeRate]);

    useEffect(() => {
        checkoutRef.current.scrollIntoView({ behavior: "smooth" });
    }, [isOpen]);

    useEffect(() => {
        setIsLogged(auth.token !== "");
        setIsOTPConfirmed(auth.token !== "");
    }, [auth.token]);

    const handleRegistrationSuccess = () => {
        setIsRegistered(true);
    };

    const handleOTPSuccess = () => {
        setIsOTPConfirmed(true);
    };

    const handleAuthToggle = (value) => {
        setAuthToggle(value);
    };

    function calculateCharge() {
        // console.log(chargeRate, bookingFEE)
        const $totalCharge = (chargeRate + bookingFEE) / 100;
        return $totalCharge; // Format to two decimal places
    }

    const [isTermsAgreed, setIsTermsAgreed] = useState(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const handleAgreementChange = (event) => {
        const value = event.target.value;
        if (value === 'agree') {
            setIsTermsAgreed(true);
            setIsPaymentLoading(true);
            setTimeout(() => {
                paymentRef.current.scrollIntoView({ behavior: "smooth" });
            }, 500);
            setTimeout(() => {
                setIsPaymentLoading(false);
            }, 2000);
            console.log("User agreed");
        } else {
            setIsTermsAgreed(false);
            console.log("User did not agree");
        }
    };


    return (
        <div>
            <div ref={checkoutRef}></div>
            <Box sx={{ maxWidth: 600, margin: "auto" }}>
                <Typography variant="h4" sx={{ mb: 3, ...textStyle.color, fontWeight: 600 }}>
                    Month-To-Month Rental
                </Typography>
                <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.secondary }}>
                    Review your subscription details before proceeding.
                </Typography>

                <GymDetailsHeader Data={selectedSite} showProfilePic={true} horizontal={true} mode={mode} />

                <Box sx={{ p: 3, borderRadius: 2 }}>

                    <div className="summary-box">
                        <h1
                            style={{
                                ...spacingStyles.munset,
                                ...spacingStyles.pt20,
                                ...colors.black,
                            }}
                        >
                            Payment Plan
                        </h1>


                        <div style={{ ...spacingStyles.pt40 }}></div>
                        <div
                            className="flexRow spaceBtw"
                            style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}
                        >
                            <span style={{ ...colors.black, textTransform: "capitalize" }}>{plan} Cost</span>
                            <span style={{ ...colors.black }}>${(chargeRate / 100).toFixed(2)}</span>
                        </div>

                        <div
                            className="flexRow spaceBtw"
                            style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}
                        >
                            <span style={{ ...colors.black, textTransform: "capitalize" }}>Technology Fee </span>
                            <span style={{ ...colors.black }}>${(bookingFEE / 100).toFixed(2)}</span>
                        </div>
                        {/* <div className="flexRow spaceBtw" style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}><span>Tax</span><span>$0.00</span></div> */}

                        <div>
                            <div className="flexCol">
                                <h3
                                    style={{
                                        ...spacingStyles.munset,
                                        ...spacingStyles.pt20,
                                        ...textStyle.color,
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    Total {plan} Payment
                                </h3>
                                <h1
                                    // className="green"
                                    style={{
                                        ...spacingStyles.munset,
                                        ...spacingStyles.pt10,
                                        ...textStyle.color,
                                    }}
                                >
                                    $ {calculateCharge().toFixed(2)}
                                </h1>
                            </div>
                        </div>
                    </div>
                </Box>

                <Box sx={{ pb: 4 }}>
                    <LeaseAgreement
                        gymName={selectedSite.profile.name}
                        gymAddress = {selectedSite.profile.location.address}
                        trainerName={userData.user_name + " "+ userData.user_lastname}
                        trainerEmail={userData.email}
                        trainerContact={userData.contact}
                        amount={(chargeRate / 100).toFixed(2)}
                        state={selectedSite.profile.location.state}
                        onAgreementChange={handleAgreementChange}
                        mode = {mode}
                        paymentFrequency = {paymentFrequency}
                    />
                </Box>
                {!isRegistered && !isLogged && (
                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Reserve now.
                        </Typography>
                        <Button
                            variant={authToggle === "Register" ? "contained" : "outlined"}
                            onClick={() => handleAuthToggle("Register")}
                            sx={{ mr: 1 }}
                        >
                            Register
                        </Button>
                        <Button
                            variant={authToggle === "Login" ? "contained" : "outlined"}
                            onClick={() => handleAuthToggle("Login")}
                        >
                            Login
                        </Button>

                        <Box sx={{ mt: 2 }}>
                            {authToggle === "Login" && <LoginForm onSuccess={handleRegistrationSuccess} mode={mode} />}
                            {authToggle === "Register" && <RegisterForm onSuccess={handleRegistrationSuccess} mode={mode} />}
                        </Box>
                    </Box>
                )}

                {isRegistered && !isOTPConfirmed && (
                    <Box sx={{ mt: 3 }}>
                        <OTPForm onSuccess={handleOTPSuccess} />
                    </Box>
                )}

                {isLogged && isTermsAgreed && (
                    <>
                        {isPaymentLoading && (
                            <Box sx={{ mt: 8, pb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', color: "orange" }}>
                                <CircularProgress />
                            </Box>
                        )}
                        <Box sx={{ pb: 4 }}>
                            <div ref={paymentRef}></div>
                            <StripeSubscription subscriptionData={{ plan, price, slug, paymentFrequency }} isOpen={isOpen} mode={mode} />
                        </Box>
                    </>
                )}


            </Box>
        </div>
    );
};

export default SubscriptionCheckout;
