import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const initialCompletionState = {
    Setup: false,
    Description: false,
    ProfilePic: false,
    Gallery: false,
    Space: false,
    Amenities: false,
    Location: false,
    Schedule: false,
    Special: false,
    SetupComplete: false,
};

const useOnboardingStatus = () => {
    const [completionStatus, setCompletionStatus] = useState(initialCompletionState);

    const businessData = useSelector((state) => state.business);
    const profilePic = useSelector((state) => state.user.profilepic);
    const { gallery } = useSelector((state) => state.gallery);
    const location = useSelector((state) => state.location.address);
    const scheduleState = useSelector((state) => state.schedule);

    const onboarding = useSelector((state) => state.onboarding);
    const space = useSelector((state) => state.onboarding.space);
    const amenities = useSelector((state) => state.onboarding.amenities);
    const equipment = useSelector((state) => state.onboarding.equipment);
    const extras = useSelector((state) => state.onboarding.extras);

    const SpaceHasSpecialInstructions = () => {
        return space.instructions?.special_instructions !== null ||
            space.instructions?.special_rules !== null ||
            space.instructions?.special_access !== null;
    };

    const AmenitiesHasAnyTrueValue = () => {
        const hasTrueInObject = (obj) => Object.values(obj).some((value) => value === true);
        return hasTrueInObject(amenities) || hasTrueInObject(equipment) || hasTrueInObject(extras);
    };

    const LocationHasChangedFromDefault = () => {
        return location.address !== "West Palm Beach, FL 33401, USA";
    };

    const ScheduleHasAvailability = () => {
        return Object.keys(scheduleState).some((dayKey) => {
            const dayHours = scheduleState[dayKey];
            return dayHours.some((slot) => slot.select === true);
        });
    };

    useEffect(() => {
        setCompletionStatus((prevStatus) => ({
            ...prevStatus,
            Description: businessData.name !== "" && businessData.name !== null,
            ProfilePic: profilePic,
            Gallery: gallery.length > 0,
            Space: space.chargeRate > 0,
            Amenities: AmenitiesHasAnyTrueValue(),
            Location: LocationHasChangedFromDefault(),
            Schedule: ScheduleHasAvailability(),
            Special: SpaceHasSpecialInstructions(),
        }));
    }, [businessData, profilePic, gallery, onboarding]);

    return completionStatus;
};

export default useOnboardingStatus;
